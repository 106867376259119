import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const HeptagramIcon = (props: KeyEventsIconProps) => {
  const { width = 14, height = 14, className, fill = "black" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <path
        d="M2.67798 5.73754L1.34123 3.41585C1.05297 2.9154 1.52654 2.32055 2.07886 2.48926L4.64105 3.27164C4.95872 3.36873 5.29757 3.20507 5.41933 2.89595L6.40094 0.403328C6.61251 -0.133968 7.37275 -0.1346 7.58527 0.402379L8.57115 2.89342C8.69338 3.20223 9.03255 3.36525 9.35006 3.26769L11.911 2.48104C12.463 2.31153 12.9375 2.90543 12.6502 3.40636L11.3172 5.73027C11.152 6.01836 11.236 6.38521 11.5103 6.57258L13.7221 8.08422C14.1988 8.4101 14.0303 9.15137 13.4595 9.23913L10.8116 9.64598C10.4833 9.69642 10.2488 9.99084 10.2733 10.3221L10.4703 12.9939C10.5129 13.5698 9.82821 13.9002 9.40366 13.5087L7.43474 11.6921C7.1906 11.4667 6.81427 11.4671 6.57045 11.6929L4.60453 13.5127C4.18076 13.905 3.49547 13.5758 3.53705 12.9997L3.72964 10.3276C3.75352 9.99637 3.51871 9.70227 3.19029 9.65246L0.541608 9.25004C-0.0293673 9.16323 -0.199189 8.42228 0.277071 8.0956L2.48618 6.58017C2.7602 6.39232 2.84369 6.02532 2.67798 5.73754Z"
        fill={fill}
      />
    </svg>
  );
};

export default HeptagramIcon;
