import {
  Array,
  Record,
  Number,
  Static,
  String,
  Union,
  Literal,
  Boolean
} from "runtypes";

export const MilestoneCalendarKeyIconValidator = Union(
  Literal("circle"),
  Literal("eclipse"),
  Literal("triangle"),
  Literal("square"),
  Literal("diamond"),
  Literal("pentagon"),
  Literal("heptagram"),
  Literal("dash"),
  Literal("bar")
);

export type MilestoneCalendarKeyIcon = Static<
  typeof MilestoneCalendarKeyIconValidator
>;

const MilestoneCalendarKeyTypeValidator = Union(
  Literal("launch"),
  Literal("product_milestone"),
  Literal("custom"),
  Literal("end_to_end_milestone")
);

export type MilestoneCalendarKeyType = Static<
  typeof MilestoneCalendarKeyTypeValidator
>;

const MilestoneCalendarKeyValidator = Record({
  id: Number,
  color: String,
  label: String,
  order: Number,
  icon: MilestoneCalendarKeyIconValidator,
  type: MilestoneCalendarKeyTypeValidator,
  isDeletable: Boolean,
  isHiddenOnLegend: Boolean
});

export type MilestoneCalendarKey = Static<typeof MilestoneCalendarKeyValidator>;

export const MilestoneCalendarKeysResponseValidator = Record({
  keys: Array(MilestoneCalendarKeyValidator)
});

export type MilestoneCalendarKeysResponse = Static<
  typeof MilestoneCalendarKeysResponseValidator
>;
