import React, { useEffect, useState } from "react";
import "./ChangeLogTable.scss";
import ChangeLogTableFilters, {
  Filters
} from "./filters/ChangeLogTableFilters";
import Table, { TableColumnsType } from "common-components/table/Table";
import { useQuery } from "@tanstack/react-query";
import {
  ChangeLogFilters,
  ChangeLogInfo,
  ChangeLogItem,
  FilterItems,
  GetChangeLogResponse,
  getChangeLog,
  getChangeLogItemNames
} from "api/change-log";
import { Text } from "common-components";
import { PageInfo, SortInfo } from "api/common";
import { TablePaginationConfig, FilterValue, SorterResult } from "@opsdti-global-component-library/amgen-design-system/node_modules/antd/es/table/interface";

const namespace = "rts-pa-change-log-table";

const initialFilters: Filters = {
  dateRange: [],
  agendaItemIds: [],
  changeTypes: []
};

const initialFilterApi: ChangeLogFilters = {
  dateRange: [],
  agendaItemIds: [],
  changeTypes: []
}


const defaultPageInfo: PageInfo = {
  pageSize: 10,
  pageNumber: 1,
  count: 0
}

const defaultSortInfo: SortInfo = {
  property: "created_date",
  direction: "descend"
}

const defaultInfo: ChangeLogInfo = {
  filters: initialFilterApi,
  sortInfo: defaultSortInfo,
  pageInfo: defaultPageInfo
}

const columns: TableColumnsType<ChangeLogItem> = [
  {
    title: "Date and Time",
    dataIndex: "created_date",
    className: `${namespace}-date-requested`,
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    render: (_, item) => {
      return (
        <>
          <Text>{item.createdDate}</Text>
        </>
      );
    },
    width: "175px"
  },
  {
    title: "Editor",
    dataIndex: "change_by",
    width: "125px",
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    render: (_, item) => {
      return (
        <>
          <Text>{item.changeBy}</Text>
        </>
      );
    },
  },
  {
    title: "Item Name",
    dataIndex: "agenda_item_name",
    width: "200px",
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    render: (_, item) => {
      return (
        <>
          <Text strong>{item.agendaItemName}</Text>
        </>
      );
    },
  },
  {
    title: "Change Type",
    dataIndex: "change_type",
    width: "150px",
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    render: (_, item) => {
      const words = item.changeType.split('_');
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
      return (
        <>
          <Text>{words.join(' ')}</Text>
        </>
      );
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    width: "350px",
    sorter:  true,
    sortDirections: ['ascend', 'descend'],
    render: (_, item) => {
      return (
        <>
          <Text>{item.description}</Text>
        </>
      );
    }
  }
];

export const ChangeLogTable = () => {
  const [tableData, setTableData] = useState<ChangeLogItem[]>([]);
  const [originalData, setOriginalData] = useState<ChangeLogItem[]>([]);
  const [filterItems, setFilterItems] = useState<FilterItems[]>([]);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [info, setInfo] = useState<ChangeLogInfo>(defaultInfo)
  const [count, setCount] = useState<number>(0);

  const { data } = useQuery<GetChangeLogResponse>(
    ["change-log", info],
    () =>
      getChangeLog(
        info
  ));

  const fetchChangeLogNames = async () => {
    const names = await getChangeLogItemNames();
    setFilterItems(names.data)
  };



  useEffect(() => {
    if (!data) {
      return;
    } else {
      if (initialLoad === true) {
        setInitialLoad(false);
        fetchChangeLogNames();
    }

      if (originalData.length === 0) {
        setOriginalData(data.data);
      }

      setTableData(data.data);


      setCount(data.info.pageInfo.count);

    }

  }, [data, initialLoad, originalData]);

  const handleTableChange = (newPagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<ChangeLogItem> | SorterResult<ChangeLogItem>[]) => {

    const newApiInfo = {
      ...info,
      pageInfo: {pageNumber: newPagination.current,
        pageSize: newPagination.pageSize,
        count: 0
      },
      sortInfo: {
        property: !Array.isArray(sorter) && sorter.order ? sorter.field?.toString() : defaultSortInfo.property,
        direction: !Array.isArray(sorter) && sorter.order || defaultSortInfo.direction
      }    
    };
    
    setInfo(newApiInfo);
  };

  const handleFilterChange = (filters: Filters) => {
    const newApiFilters = {
      ...info,
      filters: {
        dateRange: filters.dateRange.length === 2 ? [filters.dateRange[0].toString(), filters.dateRange[1].toString()] : [],
        agendaItemIds: filters.agendaItemIds,
        changeTypes: filters.changeTypes
      },
      pageInfo: {
        ...info.pageInfo,
        pageNumber: 1
      }
    };

    setInfo(newApiFilters);
    setFilters(filters);
  }


  return (
    <div className={namespace}>
      <ChangeLogTableFilters filters={filters} filterItems={filterItems} tableData={originalData} onChange={handleFilterChange}  />
      <Table columns={columns} dataSource={tableData.map(item => ({ ...item, key: item.id }))} 
      pagination={{
        position: ["bottomLeft"],
        pageSizeOptions: ['5', '10', '20', '50', '100'],
        current: info.pageInfo.pageNumber || 1,
        pageSize: info.pageInfo.pageSize || 10,
        showSizeChanger: true,
        total: count || 1,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${count ? count : "..."} items`
      }}
      onChange={handleTableChange}
       />
    </div>
  );
};

export default ChangeLogTable;
