// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[2]!../../../../../../node_modules/resolve-url-loader/index.js??ruleSet[1].rules[1].oneOf[7].use[3]!../../../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[7].use[4]!../../../../../../node_modules/simplebar-react/dist/simplebar.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-simplebar].curation-fix-vertical-scrollbar-overlap{max-width:calc(100% + 10px);width:calc(100% + 10px);padding-right:10px;padding-bottom:1px}[data-simplebar].curation-fix-horizontal-scrollbar-overlap{max-height:calc(100% + 10px);height:calc(100% + 10px);padding-bottom:10px}[class*=loader-module]{display:flex;align-items:center;justify-content:center}.curation-field-color-status{display:grid;grid-row-gap:16px;row-gap:16px}.curation-field-color-status .curation-submission-field-gray-box svg{position:relative;top:2px}`, "",{"version":3,"sources":["webpack://./src/styles/simplebar.scss","webpack://./src/styles/loader.scss","webpack://./src/components/submission-forms/common/fields/color-status/ColorStatus.scss"],"names":[],"mappings":"AAEA,yDACE,2BAAA,CACA,uBAAA,CACA,kBAAA,CACA,kBAAA,CAGF,2DACE,4BAAA,CACA,wBAAA,CACA,mBAAA,CCZF,uBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CCDF,6BACE,YAAA,CACA,iBAAA,CAAA,YAAA,CAGE,qEAEE,iBAAA,CACA,OAAA","sourcesContent":["@import \"simplebar-react/dist/simplebar.min.css\";\n\n[data-simplebar].curation-fix-vertical-scrollbar-overlap {\n  max-width: calc(100% + 10px);\n  width: calc(100% + 10px);\n  padding-right: 10px;\n  padding-bottom: 1px; //fixes container \"eating\" content; e.g. last accordion's button's border in rep modal\n}\n\n[data-simplebar].curation-fix-horizontal-scrollbar-overlap {\n  max-height: calc(100% + 10px);\n  height: calc(100% + 10px);\n  padding-bottom: 10px;\n}\n","[class*=\"loader-module\"] {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n","@import \"styles\";\n\n.curation-field-color-status {\n  display: grid;\n  row-gap: 16px;\n\n  .curation-submission-field-gray-box {\n    svg {\n      //move icons down a little bit\n      position: relative;\n      top: 2px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
