import { StatusValidator } from "api/common";
import { MilestoneCalendarKeyIconValidator } from "api/milestone-calendar-key/milestone-calendar-key-common";
import {
  Boolean,
  Record,
  Static,
  String,
  Array,
  Dictionary,
  Literal,
  Union
} from "runtypes";

const IndividualDropdownValuesValidator = Record({
  item: Array(String),
  status: Array(String),
  key: Array(String),
  date: Array(String),
  itemIdMapping: Dictionary(String, String),
  keyIdMapping: Dictionary(String, String)
});
export type IndividualDropdownValues = Static<
  typeof IndividualDropdownValuesValidator
>;

export const ActivityLogDropdownValuesDataValidator = Record({
  workstream: String,
  dropdownName: Array(String),
  dropdownValues: IndividualDropdownValuesValidator
});
export type ActivityLogDropdownValuesData = Static<
  typeof ActivityLogDropdownValuesDataValidator
>;

// Extended validator
export const ActivityLogMilestoneCalendarKeyIconValidator = Union(
  MilestoneCalendarKeyIconValidator, // reuse the original
  Literal("gray_rectangle")
);

export type ActivityLogMilestoneCalendarKeyIcon = Static<
  typeof ActivityLogMilestoneCalendarKeyIconValidator
>;

const TimelineDataValidator = Record({
  approvalRequired: Boolean,
  workstreamUrl: String,
  approvalStatus: String,
  status: StatusValidator.optional().nullable(),
  calendarKeyLabel: String.optional().nullable(),
  calendarKeyIcon:
    ActivityLogMilestoneCalendarKeyIconValidator.optional().nullable(),
  calendarKeyColor: String.optional().nullable(),
  milestoneId: String.optional().nullable(),
  timestamp: String,
  workstream: String,
  eventId: String,
  eventLogId: String,
  itemId: String,
  id: String,
  activityLogMessageTrimmed: String,
  activityLogMessage: String
});
export type TimelineData = Static<typeof TimelineDataValidator>;

export const ActivityLogDataValidator = Record({
  timelineData: Array(TimelineDataValidator),
  aiSummary: String
});

export type ActivityLogData = Static<typeof ActivityLogDataValidator>;
