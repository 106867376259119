import React, { useContext } from "react";
import { Container } from "@gitlab-rtsensing/component-library";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./SubmissionsPage.scss";
import SubmissionsPageHeader from "./header/SubmissionsPageHeader";
import SubmissionsTable from "components/submissions-table/SubmissionsTable";
import PaTabNavigation from "components/pa-tab-navigation/PaTabNavigation";

const namespace = "rts-pa-submissions-page";

export default function AgendaGridPage(): JSX.Element {
  const isLoaded = useContext(PermissionsContext).isLoaded;
  const hasPerms = useContext(PermissionsContext).submissions;

  return (
    <>
      <div className={namespace}>
        <PermissionsWrapper permissions={isLoaded}>
          <Container>
            <PermissionsWrapper permissions={hasPerms}>
              <PaTabNavigation />
              <SubmissionsPageHeader />
              <SubmissionsTable />
            </PermissionsWrapper>
          </Container>
        </PermissionsWrapper>
      </div>
    </>
  );
}
