import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const BarIcon = (props: KeyEventsIconProps) => {
  const { width = 15, height = 14, className, fill = "#FDB81E" } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="3" width="14" height="8" rx="2" fill={fill} />
    </svg>
  );
};

export default BarIcon;
