import React from "react";
import {
  Drawer as DSDrawer,
  DrawerProps
} from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";

const namespace = "rts-pa-drawer";

type Props = DrawerProps;

export const Drawer = (props: Props) => {
  const className = classNames(namespace, props.className);

  return <DSDrawer {...props} className={className} />;
};

export default Drawer;
