import React, { useEffect, useState, useContext } from 'react';
import { Title, Card, Tab, ThemeContext } from '@opsdti-global-component-library/amgen-design-system'
import { Loader } from "@gitlab-rtsensing/component-library";

const BDPage: React.FC = () => {
    const newsTabKeys = ["Emerging Platform News", "Competitor Investments", "VC Investments"];
    const { theme } = useContext(ThemeContext)
    const { colorBgLayout } = theme?.token as any

    const tabsWithChildren: Tab[] = newsTabKeys.map((key) => ({
        key,
        label: key,
        children: <Card>{key}</Card>
    }));
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);

    useEffect(() => {
        setIsDataLoading(false);
    }, []);

    return (
        <div>
            <Title level={2}>BD News</Title>
            <Card
                divider={false}
                tabList={tabsWithChildren}
                defaultActiveTabKey={newsTabKeys[0].toString()}
                bordered={false}
                style={{ 
                    backgroundColor: colorBgLayout,
                    boxShadow: "none",
                } as React.CSSProperties}
            >
                <div className="ext-article-list-wrapper ext-card-height ext-border-top-0" >
                    {isDataLoading && <Loader />}
                </div>
            </Card>
        </div>
    );
};

export default BDPage;