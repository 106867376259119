import { MilestoneCalendarKeysResponse } from "api";
import {
  MilestoneBase,
  MilestoneRange,
  RowItem
} from "components/key-events-calendar/calendarTypes";
import dayjs from "dayjs";

export const exportMilestonesToCSV = (
  data: RowItem[],
  apiCalendarKeys?: MilestoneCalendarKeysResponse
) => {

  const timeStamp = [
    `Exported ${dayjs().tz().format("M/D/YYYY; hh:mm A z")}`,
    "\n"
  ]

  const headers = [
    "Row Name",
    "Milestone Name",
    "Calendar Key",
    "Milestone Start Date",
    "Milestone End Date",
    "Start Quarter",
    "End Quarter"
  ];

  const getQuarter = (month: number, year: number): string => {
    if (!month && !year) {
      return "";
    }
    return `Q${Math.ceil(month / 3)} '${year.toString().substring(2)}`;
  };

  const getCalendarKeyLabel = (id: number) => {
    return apiCalendarKeys?.keys.find(key => key.id === id)?.label || "";
  };

  const mapMilestones = (
    row: RowItem,
    type: "fixedMilestones" | "rangeMilestones"
  ) => {
    return row[type].map((milestone: MilestoneBase | MilestoneRange) => [
      row.name,
      milestone.name,
      getCalendarKeyLabel(milestone.milestoneCalendarKeyId),
      milestone.startDate,
      (milestone as MilestoneRange)?.endDate || "",
      getQuarter(milestone.startMonth, milestone.startYear),
      getQuarter(
        (milestone as MilestoneRange).endMonth,
        (milestone as MilestoneRange).endYear
      )
    ]);
  };

  // Flatten the data and replace any commas with semicolons
  const rows = data.flatMap((row: RowItem) => {
    const fixedMilestones = mapMilestones(row, "fixedMilestones");
    const rangeMilestones = mapMilestones(row, "rangeMilestones");
    return fixedMilestones.concat(rangeMilestones);
  });

  const csvContent = [
    timeStamp.join(","),
    headers.join(","), // Headers remain joined by commas
    ...rows.map(
      row => row.map(cell => {
        // Replace commas with semicolons
        let newCell = cell.toString().replace(/,/g, ";").replace(/"/g, `""`)
        if (newCell.includes('"')) {
          newCell = `"${newCell}"`
        }
        return newCell
      }
    ).join(","))
  ].join("\n"); // Add a newline between rows

  // Create a blob and download the file
  const blob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "keyEventsCalendar.csv");
  link.click();
};
