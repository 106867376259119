import React, { useContext } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import pages from "pages/pages";
import { PermissionsContext } from "global/permissions";
import TabNavigation, {
  TabItem
} from "common-components/tab-navigation/TabNavigation";
import "./PaTabNavigation.scss";
import { EntityListItem } from "api";
import { getAgendaId, getKeyEventsId } from "utils/multitenancy";

const namespace = "rts-pa-tab-navigation";

enum TabKeys {
  agenda = "agenda",
  keyEvents = "keyEvents"
}


const PaTabNavigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const perms = useContext(PermissionsContext);

  const hasEventPerms = () => {
    return perms.keyEvents?.calendarList?.length > 0;
  };

  const hasAgendaPerms = () => {
    return perms.grid.agendaList?.length > 0;
  };

  //Determine if user should view tabs
  const hasPerms = hasAgendaPerms() && hasEventPerms();

  //Get active page info
  const isPageMatch = (url: string) => matchRoutes([{ path: url }], location);

  let activeKey: TabKeys;
  let activePerm: EntityListItem | undefined;

  if (isPageMatch(pages.keyEvents.path)) {
    activeKey = TabKeys.keyEvents;
    activePerm = perms?.keyEvents?.calendarList.find((e) => e.id?.toString() === getKeyEventsId())
  } else {
    activeKey = TabKeys.agenda;
    activePerm = perms?.grid?.agendaList.find((a) => a.id?.toString() === getAgendaId())
  }

  //Build tabs
  const getSoftLinkId = (
    navFrom: EntityListItem | undefined,
    navToOptions: EntityListItem[]
  ) => {
    //First, try to match by title
    let navTo = navToOptions
      ?.find(i => i.title && i.title === navFrom?.title);

    //If none found, default to first
    if (!navTo) 
      navTo = navToOptions[0];

    return navTo?.id.toString();
  };

  const handleTabClick = (navToKey: string) => {
    if (navToKey === activeKey) return;

    switch (navToKey) {
      case TabKeys.agenda:
        navigate(
          pages.grid.go(
            getSoftLinkId(activePerm, perms.grid?.agendaList)
          )
        );
        break;
      case TabKeys.keyEvents:
        navigate(
          pages.keyEvents.go(
            getSoftLinkId(activePerm, perms.keyEvents?.calendarList)
          )
        );
        break;
      default:
        throw new Error("Unable to navigate to tab. Reason: not found");
    }
  };

  const tabs: TabItem[] = [
    {
      key: TabKeys.agenda,
      label: "Prioritized Agenda",
      onClick: () => handleTabClick(TabKeys.agenda)
    },
    {
      key: TabKeys.keyEvents,
      label: "Key Events",
      onClick: () => handleTabClick(TabKeys.keyEvents)
    }
  ];

  return hasPerms ? (
    <TabNavigation items={tabs} activeKey={activeKey} className={namespace} />
  ) : (
    <></>
  );
};

export default PaTabNavigation;

