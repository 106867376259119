import React, { useState } from "react";
import Card from "../../components/card";
import { Button } from "@gitlab-rtsensing/component-library";
import { ArrowRightIcon } from '@opsdti-global-component-library/amgen-design-system';
import dayjs from "../../utils/dayjs";
import "./index.scss";

type CaroualCardProps = {
  onViewMoreBtnClick?: any;
  cardData?: any;
  setPopupModalHeaderData?: any;
  setCompanyPageUrl?: any;
};

const CourselCard: React.FC<CaroualCardProps> = (props: CaroualCardProps) => {
  const [toggleState] = useState("earning");
  const {
    cardData,
    setPopupModalHeaderData,
    setCompanyPageUrl,
  } = props;

  const { onViewMoreBtnClick } = props;
  const onOpenPopUpModal = () => {
    if (onViewMoreBtnClick) onViewMoreBtnClick(true, toggleState);
    setPopupModalHeaderData({
      companyName: cardData?.company_short_name,
      quarter: cardData?.quarter,
      year: cardData?.year,
      imageUrl: cardData?.image_url,
    });
    setCompanyPageUrl(cardData?.company_ticker);
  };

  const arr = cardData?.summary?.slice(1)?.split("\n-");



const showTag = (date: string) => { 
  const today = dayjs()
  if (today.isSame(date, 'day')){
     return true
  }
  return false
}  

const checkIfFutureDate = (date: string) => {
  const today = dayjs();
  if (today?.isBefore(date, 'day'))
    return true
  return false  
}

const formatDateStr = (date: string) => {
  //remove leading zeros
  return date?.replace(/(^|\/)0+(?=\d)/g, '$1');
}

const getCardMessage = (date: string, quarter: string) => {
  const isFuture = checkIfFutureDate(date)
  if(isFuture){
    return <div>
      {`Q${quarter} Earnings Report is expected before EOD on `}
      <span style={{ fontWeight:"bold"}}>{formatDateStr(date)}</span>
    </div>
  }
  return <div>
    Executive Insights are pending or not available for this release
  </div>
}

  return (
    <div className="ext-col-lg-12 ext-col-md-12 ext-col-sm-12" style={{height:"100%"}}> 
   
    {(cardData?.company_short_name == "") ?
    
    ( <div className="ext-card card ext-card-outline ext-container" >

      <>
      <div style={{ display: 'flex', justifyContent: 'space-between'}}>
       <span> Upcoming Earnings Reports </span><div style={{paddingLeft:"10px"}}></div><ArrowRightIcon color="black" width={24} height={24} /></div>
      </>
    </div>) :(    
      <Card
        cardHeader={`${cardData?.company_short_name} Insights Q${cardData?.quarter} ${cardData?.year} `}
        imageSrc={cardData?.image_url}
        showImage={true}
        showNewTag={showTag(cardData?.earnings_call_date)}
        backgroundColor={checkIfFutureDate(cardData.earnings_call_date)?"#F0F0F0":""}
      >
        
      
        <>
          <div
            className={`ext-d-flex ext-earning-tab-container ${
              cardData?.company_short_name !== "Amgen"
                ? "ext-justify-content-start"
                : "ext-justify-content-start"
            }`}
          > 
          </div>
          <div className="ops-mx-3">
            <div className="ext-earning-card-header ">
              <>
                {`Key Investor Relations Insights ${checkIfFutureDate(cardData.earnings_call_date) ? "" : `(${formatDateStr(cardData.earnings_call_date)})`}`}
              </>
            </div>
            <div className="ext-earning-card-content ops-mx-1">
              {cardData?.summary === "" ? (
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "80px",
                    fontSize: "14px",
                  }}
                >
                  {getCardMessage(cardData?.earnings_call_date, cardData?.quarter)}
                </div>
              ) :
              (
                <ol style={{ paddingLeft: "18px" }}>
                  {arr?.map((item: any, index: number) => {
                    return (
                      <li key={index} style={{ listStyleType: "disc" }}>
                        {item}
                      </li>
                    );
                  })}
                </ol>
              )}
            </div>
            {!checkIfFutureDate(cardData.earnings_call_date)?
            (<div className="ops-mt-3 ops-mb-3">
                <Button
                  label="VIEW EARNINGS CALL SUMMARY"
                  onClick={() => onOpenPopUpModal()}
                  type="secondary"
                  className="ext-earning-view-button"
                />
            </div>):""}
          </div>
        </>

      </Card>)}
    </div> 
  );
};

export default CourselCard;
