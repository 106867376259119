import React from "react";
import { Tag as DSTag } from "@opsdti-global-component-library/amgen-design-system";
import classNames from "classnames";
import { TagProps } from "@opsdti-global-component-library/amgen-design-system/lib/components/elements/tag/tag";
import "./Tag.scss";

const namespace = "rts-pa-tag";

type Props = {
  isToggled?: boolean;
} & TagProps;

export const Tag = (props: Props) => {
  const className = classNames(namespace, props.className);

  return (
    <DSTag
      {...props}
      className={props.isToggled ? `${className}-checked` : className}
    />
  );
};

export default Tag;
