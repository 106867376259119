import React, { useEffect, useState } from "react";
import "./KeyEventsCalendarRow.scss";
import { Columns, RowItem } from "../calendarTypes";
import { Text } from "common-components";
import KeyEventsCalendarRowBorder from "./border/KeyEventsCalendarRowBorder";
import KeyEventsCalendarRowMilestoneFixed from "./milestone/fixed/KeyEventsCalendarRowMilestoneFixed";
import KeyEventsCalendarRowMilestoneRange from "./milestone/range/KeyEventsCalendarRowMilestoneRange";
import classNames from "classnames";
import KeyEventsCalendarRowDateline from "./dateline/KeyEventsCalendarRowDateline";
import KeyEventsCalendarRowMenu from "./menu/KeyEventsCalendarRowMenu";
import DragIcon from "icons/drag-icon";
import { Draggable } from "react-beautiful-dnd";
import { useCalendarModeHelper } from "global/use-key-events-calendar-mode-helper";
import { useCalendarContext } from "pages/key-events/CalendarContext";
import { FilterAccordionCheckboxType } from "components/key-events/filter/FilterAccordion";

export const namespace = "rts-pa-key-events-calendar-row";

type Props = {
  columns: Columns;
  item: RowItem;
  index: number;
  isDraggable: boolean;
};

export const isIdInFilteredData = (
  id: number,
  filteredOptions: FilterAccordionCheckboxType[]
) => {
  return filteredOptions.some(item => item.id === id.toString());
};

export default function KeyEventsCalendarRow(props: Props): JSX.Element {
  const calendarMode = useCalendarModeHelper();
  const { calendarFilters } = useCalendarContext();
  const [filteredKeys, setFilteredKeys] = useState<
    FilterAccordionCheckboxType[]
  >([]);
  const [filteredRows, setFilteredRows] = useState<
    FilterAccordionCheckboxType[]
  >([]);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const rightContanerClassName = classNames(`${namespace}-right-container`, {
    odd: props.index % 2 === 1
  });

  useEffect(() => {
    // Calculate filtered keys and rows
    const updatedFilteredKeys = (calendarFilters.keys || []).filter(
      item => item?.isChecked
    );
    const updatedFilteredRows = (calendarFilters.rows || []).filter(
      item => item?.isChecked
    );

    setFilteredKeys(updatedFilteredKeys);
    setFilteredRows(updatedFilteredRows);

    setIsFilterActive(
      updatedFilteredKeys.length > 0 || updatedFilteredRows.length > 0
    );
  }, [calendarFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  const isMilestoneGrey = (milestoneId: number): boolean => {
    // If the filter is not active, render normally
    if (!isFilterActive) return false;

    // Both key and row filters are selected
    if (filteredKeys.length > 0 && filteredRows.length > 0) {
      return !(
        isIdInFilteredData(props.item.id, filteredRows) &&
        isIdInFilteredData(milestoneId, filteredKeys)
      );
    }

    // Only key or row filter is selected
    // Row ID is in filtered data, render normally
    if (isIdInFilteredData(props.item.id, filteredRows)) return false;

    // Milestone ID is in filtered key data, render normally
    if (isIdInFilteredData(milestoneId, filteredKeys)) return false;

    // Default case: milestone should be greyed out if filter is active
    return true;
  };

  const isRowHidden = () => {
    if (!isFilterActive) return false;

    // If there are filtered rows and the row ID is not in filtered data
    if (
      filteredRows.length > 0 &&
      !isIdInFilteredData(props.item.id, filteredRows)
    ) {
      return true;
    }

    return false;
  };

  return isRowHidden() ? (
    <></>
  ) : (
    <Draggable
      draggableId={props.item.id.toString()}
      isDragDisabled={!calendarMode.isRearrangeMode}
      index={props.index}
    >
      {provided => {
        return (
          <div
            className={namespace}
            {...provided.draggableProps}
            ref={provided.innerRef}
          >
            <div className={`${namespace}-left-container`}>
              <Text>{props.item.name}</Text>

              {props.isDraggable ? (
                <div
                  className={`${namespace}-drag`}
                  {...provided.dragHandleProps}
                >
                  <DragIcon height={10} />
                </div>
              ) : (
                <KeyEventsCalendarRowMenu
                  rowId={props.item.id}
                  linkToSource={props.item.linkToSource}
                  links={props.item.links}
                />
              )}
            </div>
            <div className={rightContanerClassName}>
              <div
                className={`${namespace}-right-container-fixed`}
                style={{
                  gridTemplateColumns: props.columns.gridTemplateColumns
                }}
              >
                {props.item.fixedMilestones.map(m => (
                  <KeyEventsCalendarRowMilestoneFixed
                    key={m.id}
                    milestone={m}
                    isFaded={isMilestoneGrey(m.milestoneCalendarKeyId)}
                  />
                ))}
                {props.columns.years.map((y, i) =>
                  props.columns.years.length - 1 !== i ? (
                    <KeyEventsCalendarRowBorder key={y.year} year={y.year} />
                  ) : null
                )}
                <KeyEventsCalendarRowDateline columns={props.columns} />
              </div>
              <div
                className={`${namespace}-right-container-range`}
                style={{
                  gridTemplateColumns: props.columns.gridTemplateColumns,
                  display: !props.item.rangeMilestones.length
                    ? "none"
                    : "inherit"
                }}
              >
                {props.item.rangeMilestones.map(m => (
                  <KeyEventsCalendarRowMilestoneRange
                    key={m.id}
                    milestone={m}
                    isFaded={isMilestoneGrey(m.milestoneCalendarKeyId)}
                  />
                ))}
                {props.columns.years.map((y, i) =>
                  props.columns.years.length - 1 !== i ? (
                    <KeyEventsCalendarRowBorder key={y.year} year={y.year} />
                  ) : null
                )}
                <KeyEventsCalendarRowDateline columns={props.columns} />
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
}
