import React from "react";
import { AdvancedTooltip as AdvancedTooltipType } from "api";
import AdvancedTooltipContent from "./AdvancedTooltipContent";
import { Tooltip } from "common-components/tooltip/Tooltip";

type Props = {
  className?: string;
  tooltipIcon?: JSX.Element;
  tooltip: AdvancedTooltipType | null;
  trigger?: "hover" | "click";
  placement?: "left" | "right" | "top" | "bottom";
};
export const AdvancedTooltip = (props: Props) => {
  if (props.tooltip === null) {
    return <></>;
  }

  return (
    <Tooltip
      placement={props.placement}
      tooltipIcon={props.tooltipIcon}
      trigger={props.trigger}
    >
      <AdvancedTooltipContent
        className={props.className}
        tooltip={props.tooltip}
      />
    </Tooltip>
  );
};
