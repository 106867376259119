import React, { useState } from "react";
import "./ChangeLogTableFilters.scss";
import { Dayjs } from "dayjs";
import {
  Label,
  SelectSimple,
  Option,
  DateRangePickerSimple
} from "common-components";
import { ChangeLogItem, FilterItems } from "api/change-log";

const namespace = "rts-pa-change-log-table-filters";

const filterFieldNames: Option[] = [
  {
    label: "Title change",
    value: "title_change"
  },
  {
    label: "Position change",
    value: "position_change"
  },
  {
    label: "Addition",
    value: "addition"
  },
  {
    label: "Removal",
    value: "removal"
  }
];

export type Filters = {
  dateRange: Dayjs[] | [];
  agendaItemIds: number[];
  changeTypes: string[];
};

type Props = {
  filters: Filters;
  tableData: ChangeLogItem[] | undefined;
  filterItems: FilterItems[];
  onChange: (filters: Filters) => void;
};

export const ChangeLogTableFilters = (props: Props) => {

  const [itemSearchValue, setItemSearchValue] = useState("");
  const [changeSearchValue, setChangeSearchValue] = useState("");


  const handleItemNamesChange = (value: string[]) => {
    props.onChange({
      ...props.filters,
      agendaItemIds: value.map(Number)});
  };

  const handleChangeTypesChange = (value: string[]) => {
    props.onChange({
      ...props.filters,
      changeTypes: value});
  };

  const handleDateRangeChange = (dates: [Dayjs | null, Dayjs | null] | []) => {
    if (dates && dates[0] && dates[1]) {
      props.onChange({
        ...props.filters,
        dateRange: [dates[0].startOf('day'), dates[1].endOf('day')]
      })
    } else {
      props.onChange({
        ...props.filters,
        dateRange: []
      });
    }
  };

  const handleItemNameSearch = (value: string) => {
    setItemSearchValue(value);
  };

  const handleItemNameReset = () => {
    setItemSearchValue("")
  };

  const filteredItems = props.filterItems.filter((item) =>    
    item.label.toLowerCase().includes(itemSearchValue.toLowerCase())  
  );

  const handleChangeTypeSearch = (value: string) => {
    setChangeSearchValue(value);
  };

  const handleChangeTypeReset = () => {
    setChangeSearchValue("")
  };

  const filteredTypes = filterFieldNames.filter((item) =>    
  item.label.toLowerCase().includes(changeSearchValue.toLowerCase())  
);


  return (
    <div className={namespace}>
      <Label className={`${namespace}-title`}>FILTER BY:</Label>
      <DateRangePickerSimple
        allowEmpty={[true, true]}
        className={`${namespace}-date-picker`}
        label="Date Range"
        format="MMM, DD YYYY"
        placeholder={["Start date", "End date"]}
        onChange={handleDateRangeChange}
        value={props.filters.dateRange.length ? [props.filters.dateRange[0], props.filters.dateRange[1]] : []}
        inputReadOnly={true}
      />
      <SelectSimple
        label="Item Name"
        options={filteredItems}
        onChange={handleItemNamesChange}
        mode="multiple"
        placeholder="Select agenda items"
        onSearch={handleItemNameSearch}
        onDeselect={handleItemNameReset}
        filterOption={false}
        >
      </SelectSimple>
      <SelectSimple
        label="Changes"
        options={filteredTypes}
        onChange={handleChangeTypesChange}
        mode="multiple"
        placeholder="Select change type"
        onSearch={handleChangeTypeSearch}
        onDeselect={handleChangeTypeReset}
      />
    </div>
  );
};

export default ChangeLogTableFilters;
