import { useState, useEffect, useRef, useMemo } from "react";
import axios , { CancelTokenSource } from "axios";
import { Loader } from "@gitlab-rtsensing/component-library";
import "../oncology/index.scss";

// Components
import Card from "../../components/card";
import ArticleCard from "../../components/article-card-dscu";
import RequestPage from "../../components/request-access-page";
import CardFilter from "../../components/cards-filter";
import DateSelector from "../../components/date-picker";
import FilterDropdown from "../../components/filter-dropdown";
import { 
  Button,
  Title,
  Pagination,
  Collapse,
  Switch,
  Select,
  Paragraph,
} from '@opsdti-global-component-library/amgen-design-system'

// Utils
import {
  apiResponse,
  lastApiResponse,
} from "../../utils/commonMethods";
import { 
  getFormattedTagListWithFilter,
  filterCountFn,
} from "../news-utils";
import {
  Tag,
  Article,
  NewsCurrentlyFilteredList,
  FilterApiResponseData,
} from '../news-types'
import dayjs, { Dayjs } from "../../utils/dayjs";
import { formatUrlParameters } from "../../api/api-config";
// Consts
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
import { SEARCH_TEXT, SWITCH_SUMMARIES_TEXT, SELECT_OPTIONS } from '../news-consts'
import ErrorMessage from "../../components/error-message";

type Filter = {
    name: string;
    field: string;
    auth: boolean | null;
    searchable: boolean;
}

type Props = {
    title: string;
    filters: Array<Filter>;
    // area: string;
    areaAuth: boolean;
}

const PAGE_LIMIT: number = 50;
const NewsView = ({ title, filters, areaAuth}: Props) => {
  const [filterList, setFilterList] = 
    useState<FilterApiResponseData>(Object.fromEntries(filters.map(filter => [filter.field, []])))
  const [isFilterListLoading, setIsFilterListLoading] = useState<boolean>(false);
  const [filterListError, setFiltersListError] = useState("");

  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [page, setPage] = useState<number>(1);
  const [upperLimit, setUpperLimit] = useState<number>(0);
  const [lowerLimit, setLowerLimit] = useState<number>(0);

  const [newsList, setNewsList] = useState<Array<Article>>();
  const [newsListError, setNewsListError] = useState("");
  const [newsListLoading, setNewsListLoading] = useState<boolean>(false);

  const [newsCount, setNewsCount] = useState<number | null>(null);
  const [paginationDate, setPaginationDate] = useState<any>("TEMP-DATE");
  const [totalFilterCount, setTotalFilterCount] = useState<number>(0);

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(89, 'days'));
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [showArticleSummary, setShowArticleSummary] = useState(true);

  const [currentlyFilteredList, setCurrentlyFilteredList] = 
    useState<NewsCurrentlyFilteredList>(Object.fromEntries(filters.map(filter => [filter.field, []])))

  const currentLimit =
    upperLimit < (newsCount || 0) ? upperLimit : newsCount;
  const todayDate: Dayjs = dayjs();

  const checkFilterBox = (value: {
    field: string;
    name: string;
    isChecked: boolean;
  }) => {
    const currentlyFilteredListKey = value.field.toLowerCase() as keyof NewsCurrentlyFilteredList
    const filterObject: Array<string> = currentlyFilteredList[currentlyFilteredListKey];
    let updatedFilterObject: any[] = [];

    if (value.isChecked) {
      filterObject.push(value.name);
      updatedFilterObject = filterObject;
    } else {
      updatedFilterObject = filterObject.filter((item: any) => {
        if (item !== value.name) {
          return true;
        }
        return false;
      });
    }

    setCurrentlyFilteredList((prevState: any) => {
      prevState[currentlyFilteredListKey] = updatedFilterObject;
      return { ...prevState };
    });
    setPage(1);
  };

  const getFilterList = async () => {
    setIsFilterListLoading(true);
    const payload = {
      filters: filters.map(filter => filter.field)
    }
    try {
      const res = await apiResponse("post", "get-filters", [], payload);
      if (res?.data.data) {
          setFilterList(res.data.data);
      }
      setIsFilterListLoading(false);
    } catch (error) {
      setIsFilterListLoading(false);
      setFiltersListError(apiErrorMessage);
    }
  };

  const getArticleList = async (
    page: number,
    startDateSelected: Dayjs,
    endDateSelected: Dayjs
  ) => {
    setNewsList([]);
    setNewsListLoading(true);
    setNewsListError("");

    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateLocal = startDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD")
    const endDateLocal = endDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD");

    const options = {
      sort_order: sortOrder,
      sort_by: "published",
      start_date: startDateLocal,
      end_date: endDateLocal,
      offset: page - 1,
      limit: PAGE_LIMIT,
      ui: 'false',
    };

    const optionUrlParameters: string = formatUrlParameters(options);
    let tagUrlParameters = ""
    Object.keys(currentlyFilteredList).forEach((filter: any) => {
      currentlyFilteredList[filter].forEach((value: string) => {
        tagUrlParameters += `&tag=${value}`
      })
    })
    const urlParameters: string = optionUrlParameters + tagUrlParameters 
    // https://external-api-dev.nimbus.amgen.com/api/v2/news/?limit=0&offset=0&sort_by=published&sort_order=desc&tag=cattag1&tag=cattag2&ui=false

    try {
      const res = await lastApiResponse("get", "news", cancelTokenSource, {}, urlParameters, "v2");
      if (res.data.status === "SUCCESS" && 
          (res.data.data.response && res.data.data.response.length !== 0) &&
          (res.data.data.count !== 0 && res.data.data.count !== null)) {
        setNewsList(res.data.data.result);
        setNewsListError("");
        // setPaginationDate(res.data.data.ingested_date); <-- may need
        setPaginationDate("TEMP-DATE")
        setNewsCount(res.data.data.count);
        setLowerLimit((page - 1) * PAGE_LIMIT + 1);
        setUpperLimit(page * PAGE_LIMIT);
      } else {
        setNewsListError(noDataMessage);
      }
      setNewsListLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setNewsList([]);
        setNewsListLoading(false);
        setNewsListError(apiErrorMessage);
      }
    }
  };

  const onDateChange = (data: any) => {
    setPage(1)
    data.fieldName === "fromDate"
      ? setStartDate(data?.data)
      : setEndDate(data?.data);
  };

  useEffect(() => {
    if (areaAuth) {
      getArticleList(page, startDate, endDate);
      setTotalFilterCount(filterCountFn(currentlyFilteredList));
    }
  }, [
    sortOrder,
    page,
    currentlyFilteredList,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (areaAuth) {
      getFilterList()
    }
  }, []);

  const resetFilter = () => {
    setCurrentlyFilteredList(Object.fromEntries(filters.map(filter => [filter.field, []])));
    setPage(1);
    setStartDate(dayjs().subtract(89, 'days'));
    setEndDate(todayDate);
  };

  const formatFilterCount = (filterCount: number) => {
    if(filterCount === 0) return ""
    return `(${filterCount})`
  }

  const filterDropdowns = useMemo(() => {
    let items: any[] = []
    filters.forEach(filter => {
        if(filter.auth === null || filter.auth === true) {
            items.push({
                children: <FilterDropdown
                    label={filter.field}
                    options={filterList[filter.field]}
                    isLoading={isFilterListLoading}
                    checkedOptions={currentlyFilteredList[filter.field]}
                    onClickCheckbox={checkFilterBox}
                    error={filterListError}
                />,
                key: filter.field,
                label: `${filter.field} ${formatFilterCount(currentlyFilteredList[filter.field].length)}`,
            })
        }
    })
    return items
  }, [filterList, isFilterListLoading, currentlyFilteredList, filterListError])

  return areaAuth ? (
    <div className="ext-oncology">
        <Title level={1}>{title}</Title>
        <div className="ext-news-container">
          <div className="ext-news-left-panel">
            <CardFilter
              cardHeader="Filter Articles"
              totalFilterCount={totalFilterCount}
            >
              <div className="ext-filter-element-container">
                <DateSelector
                  selectedDate={startDate}
                  label="PUBLISHED BETWEEN"
                  verbageText="Select publish date"
                  minDate={dayjs().subtract(1, 'years')}
                  maxDate={endDate}
                  onChange={(date: Dayjs) =>
                    onDateChange({
                      data: date,
                      fieldName: "fromDate",
                    })
                  }
                />
                <DateSelector
                  selectedDate={endDate}
                  label="AND"
                  minDate={dayjs().subtract(1, 'years')}
                  maxDate={todayDate}
                  onChange={(date) =>
                    onDateChange({ data: date, fieldName: "toDate" })
                  }
                />
                <div style={{minHeight: 24, width: '100%'}}/>
                <Collapse
                  defaultActiveKey={1}
                  items={filterDropdowns}
                />
              </div>
              <div className="ext-reset-filters">
                <Button
                  text="RESET FILTERS"
                  type="link"
                  onClick={() => resetFilter()}
                />
              </div>
            </CardFilter>
          </div>
          <div className="ext-news-right-panel">
            <Card cardHeader={title} newsCard={true}>
              <div className='ext-article-container'>
                <div className="ext-article-sorting-wrapper">
                    <Paragraph className="ext-article-card-note">
                      {SEARCH_TEXT}
                    </Paragraph>
                    <div className="ext-card-toggle-options">
                      <div className="ext-show-summary-parent">
                        <Switch
                          checked={showArticleSummary}
                          onChange={() => setShowArticleSummary(!showArticleSummary)}
                          inlineText={SWITCH_SUMMARIES_TEXT}
                        />                        
                      </div>
                      <Select
                        options={SELECT_OPTIONS}
                        defaultValue="desc"
                        onChange={(val) => setSortOrder(val)}
                      />
                  </div>
                </div>
                <div className="ext-article-list-wrapper">
                  {newsListLoading && (
                    <div className="ext-news-loader">
                      <Loader />
                    </div>
                  )}
                  {newsListError && (
                    <ErrorMessage isIcon={false} message={newsListError} />
                  )}
                  {!newsListLoading &&
                    !newsListError &&
                    newsList?.map((article: any, index: number) => {
                      const isEven = (index % 2 === 0)

                      // article.filters.foreach???
                      const tags: Array<Tag> = getFormattedTagListWithFilter(
                        article.filters,
                        {},
                        currentlyFilteredList,
                        checkFilterBox,
                      )

                      return (
                        <ArticleCard
                          key={index}
                          redirectUrl={article.url}
                          isEven={isEven}
                          title={article.title}
                          articleId={article.article_id}
                          publishDate={article.published}
                          publishSource={article.source_name}
                          tagList={tags}
                          checkboxFlag={false}
                          trashFlag={false}
                          summary={showArticleSummary ? article.article_summary : null}
                          ellipsisRequired={true}
                        />
                      );
                    })}
                </div>

                <div className="ext-article-pagination">
                  <div className="pagination-container">
                    {!newsListLoading && newsList?.length !== 0 &&
                      <div className="ext-article-pagination-wrapper">
                        <Paragraph>
                          Last update at {paginationDate?.replace("T", "T on")}
                        </Paragraph>
                        <div>
                          <Pagination
                            showSizeChanger={false}
                            showTotal={(total) => `${lowerLimit} - ${currentLimit} of ${newsCount} items`}
                            current={page}
                            total={newsCount || 0}
                            pageSize={PAGE_LIMIT}
                            onChange={(page: number) => setPage(page)}
                          />
                        </div>
                      </div>
                      }
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
    </div>
    ) : (
    <>{areaAuth && <RequestPage />}</>
  );
};

export default NewsView;
