import React, { ChangeEvent, useEffect, useState } from "react";
import "./LinksInputItem.scss";
import TrashIcon from "icons/trash-icon";
import DragIcon from "icons/drag-icon";
import { Draggable } from "@hello-pangea/dnd";
import classnames from "classnames";
import { ExternalLink } from "api";
import produce from "immer";
import ErrorMessages from "../error-messages/error-messages";
import Checkbox from "common-components/checkbox/Checkbox";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const namespace = "curation-field-links-input-item";

export type ExternalLinkExtended = ExternalLink & {
  id: string;
  isExistingItem?: boolean;
};

export type Props = {
  link: ExternalLinkExtended;
  fieldName: string;
  index: number;
  className?: string;
  onChange: (index: number, link: ExternalLinkExtended) => void;
  onDeleteClick: (index: number) => void;
  registerValidation: (id: string, getErrors: () => string[]) => () => void;
  showIsSensingExternal?: boolean;
};

const getRequiredError = (fieldName: string) => {
  return `${fieldName} is required.`;
};

const addErrorIdText = (
  linksFieldName: string,
  index: number,
  errorMsg: string
) => {
  return `${linksFieldName} - Link ${index + 1} ${errorMsg}`;
};

const LABEL_LABEL = "Text";
const HREF_LABEL = "URL";

export const LinksInputItem = (props: Props): JSX.Element => {
  const [labelErrors, setLabelErrors] = useState<string[]>([]);
  const [hrefErrors, setHrefErrors] = useState<string[]>([]);

  useEffect(() => {
    const unregisterValidation = props.registerValidation(props.link.id, () => {
      const errors: string[] = [];

      if (!props.link.label) {
        const reqErrorMsg = getRequiredError(LABEL_LABEL);

        errors.push(addErrorIdText(props.fieldName, props.index, reqErrorMsg));
        setLabelErrors([reqErrorMsg]);
      } else {
        setLabelErrors([]);
      }

      if (!props.link.href) {
        const reqErrorMsg = getRequiredError(HREF_LABEL);

        errors.push(addErrorIdText(props.fieldName, props.index, reqErrorMsg));
        setHrefErrors([reqErrorMsg]);
      }

      return errors;
    });

    return unregisterValidation;
  }, [props.link, props.registerValidation]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLink = produce(props.link, draftState => {
      draftState.label = e.target.value;
    });

    if (!e.target.value) {
      setLabelErrors([getRequiredError(LABEL_LABEL)]);
    } else {
      setLabelErrors([]);
    }

    props.onChange(props.index, newLink);
  };

  const onHrefChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newLink = produce(props.link, draftState => {
      draftState.href = e.target.value;
    });

    if (!e.target.value) {
      setHrefErrors([getRequiredError(HREF_LABEL)]);
    } else {
      setHrefErrors([]);
    }

    props.onChange(props.index, newLink);
  };

  const onIsSensingExternalChange = (e: CheckboxChangeEvent) => {
    const newLink = produce(props.link, draftState => {
      draftState.isSensingExternal = e.target.checked;
    });

    props.onChange(props.index, newLink);
  };

  const labelContainerClassname = classnames(
    `${namespace}-container-inputs-item`,
    {
      error: labelErrors.length
    }
  );

  const hrefContainerClassname = classnames(
    `${namespace}-container-inputs-item`,
    {
      error: hrefErrors.length
    }
  );

  return (
    <Draggable draggableId={props.link.id} index={props.index}>
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            <div className={`${namespace}-title`}>
              {props.link.isExistingItem && "Edited "}Link {props.index + 1}
            </div>
            <div className={`${namespace}-container`}>
              <div className={`${namespace}-container-inputs`}>
                <div className={labelContainerClassname}>
                  <input
                    id={`${props.link.id}-text`}
                    placeholder={LABEL_LABEL}
                    value={props.link.label}
                    onChange={onLabelChange}
                  />
                  <ErrorMessages errors={labelErrors} />
                </div>
                <div className={hrefContainerClassname}>
                  <input
                    id={`${props.link.id}-URL`}
                    placeholder={`${HREF_LABEL} (should include https://)`}
                    value={props.link.href}
                    onChange={onHrefChange}
                  />
                  <ErrorMessages errors={hrefErrors} />
                </div>
                {props.showIsSensingExternal && (
                  <Checkbox
                    checked={props.link.isSensingExternal || false}
                    onChange={onIsSensingExternalChange}
                  >
                    Select if the link leads to a website outside of Sensing
                  </Checkbox>
                )}
              </div>
              <div className={`${namespace}-container-controls`}>
                <TrashIcon
                  className="trash-icon"
                  height={15}
                  width={16}
                  onClick={() => props.onDeleteClick(props.index)}
                />
                <div
                  className={`${namespace}-drag`}
                  {...provided.dragHandleProps}
                >
                  <DragIcon className="drag-icon" height={12} width={16} />
                </div>
              </div>
            </div>
            {props.link.isExistingItem && (
              <div className={`${namespace}-footer`}>
                Any edits above will replace the current external link.
              </div>
            )}
          </div>
        );
      }}
    </Draggable>
  );
};

export default LinksInputItem;
