import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union
} from "runtypes";

export const PermissionRoleValidator = Union(
  Literal("admin"),
  Literal("full_viewer"),
  Literal("limited_viewer")
);

export type PermissionRole = Static<typeof PermissionRoleValidator>;

const EntityListItemValidator = Record({
  id: Number,
  title: String,
  role: PermissionRoleValidator
});

export type EntityListItem = Static<typeof EntityListItemValidator>;

const WorkspacePermissionsValidator = Record({
  pa: Boolean,
  brand: Boolean,
  pipeline: Boolean,
  supply: Boolean,
  people: Boolean,
  finance: Boolean
});

const PaPermissionsValidator = Record({
  superAdmin: Boolean,
  createAgenda: Boolean,
  activityLog: Boolean,
  agendaList: Array(EntityListItemValidator)
});

const KePermissionsValidator = Record({
  superAdmin: Boolean,
  createCalendar: Boolean,
  activityLog: Boolean,
  calendarList: Array(EntityListItemValidator)
});

const DomainPermissionsValidator = Record({
  homePdf: Boolean,
  ceoStaffNav: Boolean,
  funcNav: Boolean
});

export const AuthorizationValidator = Record({
  ws: WorkspacePermissionsValidator,
  pa: PaPermissionsValidator,
  ke: KePermissionsValidator,
  domain: DomainPermissionsValidator
});

export type Authorization = Static<typeof AuthorizationValidator>;
