import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import "./LinksInput.scss";
import { SubmissionField } from "api";
import SubmissionFieldGrayBox from "../../field-gray-box/SubmissionFieldGrayBox";
import SubmissionFieldContainer, {
  FieldMode,
  FormMode
} from "../../field-container/SubmissionFieldContainer";
import SubmissionFieldEditContainer from "../../field-edit-container/SubmissionFieldEditContainer";
import LinksInputDisplay from "./display/LinksInputDisplay";
import LinksInputItem, { ExternalLinkExtended } from "./item/LinksInputItem";
import { v4 as uuid } from "uuid";
import produce from "immer";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "@hello-pangea/dnd";
import { Button } from "@gitlab-rtsensing/component-library";
import { PlusIcon } from "icons";
import classnames from "classnames";
import Checkbox from "common-components/checkbox/Checkbox";
import ErrorMessages from "components/submission-forms/common/fields/links-input/error-messages/error-messages";

const namespace = "curation-field-links-input";

type Props = {
  formMode: FormMode;
  fieldMode: FieldMode;
  label: string;
  data: SubmissionField;
  onChange: (val: string) => void;
  isDisabled: boolean;
  isRequired: boolean;
  registerValidation: (id: number, getErrors: () => string[]) => () => void;
  overallConfirmation?: boolean;
};

const CONF_REQ_ERROR_MSG = "Confirmation is required.";

const LinksInput = (props: Props) => {
  const cachedSubmittedValue = useMemo(
    () => props.data.submittedValue,
    [props.formMode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const [links, setLinks] = useState<ExternalLinkExtended[]>([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmationErrors, setConfirmationErrors] = useState<string[]>([]);

  const validationFuncs = useRef(new Map<string, () => string[]>());
  const registerItemsValidation = useCallback(
    (id: string, getErrors: () => string[]) => {
      validationFuncs.current.set(id, getErrors);

      //unregister function
      return () => {
        validationFuncs.current.delete(id);
      };
    },
    []
  );

  //#region confirmation checkbox
  //register confirmation checkbox's validation
  useEffect(() => {
    const unregisterValidation = registerItemsValidation(props.label, () => {
      const errors: string[] = [];

      if (links.length && props.overallConfirmation && !isConfirmed) {
        errors.push(`${props.label} ${CONF_REQ_ERROR_MSG}`);
        setConfirmationErrors([CONF_REQ_ERROR_MSG]);
      }

      return errors;
    });

    return unregisterValidation;
  }, [
    isConfirmed,
    links,
    props.label,
    registerItemsValidation,
    props.overallConfirmation
  ]);

  //reset confirmation on changes
  useEffect(() => {
    setIsConfirmed(false);
    setConfirmationErrors([]);
  }, [links]);
  //#endregion

  useEffect(() => {
    props.registerValidation(props.data.fieldId, () => {
      let errors: string[] = [];

      validationFuncs.current.forEach(getErrors => {
        errors = errors.concat(getErrors());
      });

      return errors;
    });
  }, [props.data.fieldId, links]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const toExtended = (linksStr: string): ExternalLinkExtended[] => {
      const externalLinks: ExternalLinkExtended[] = JSON.parse(linksStr); //actual obj is ExternalLink

      for (const link of externalLinks) {
        link.id = uuid();
        link.isExistingItem = true;
      }

      return externalLinks;
    };

    switch (props.formMode) {
      case "submit":
        if (props.data.liveValue) {
          setLinks(toExtended(props.data.liveValue));
        }
        break;
      case "submitted":
      case "resubmit":
      case "review":
        if (props.data.submittedValue) {
          setLinks(toExtended(props.data.submittedValue));
        }
        break;
      case "view":
        break; //already initialized to []
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddClick = () => {
    const newLinks = produce(links, draftState => {
      draftState.push({ id: uuid(), href: "", label: "" });
    });

    setLinks(newLinks);

    props.onChange(JSON.stringify(newLinks));
  };

  const onDeleteClick = (i: number) => {
    const newLinks = produce(links, draftState => {
      draftState.splice(i, 1);
    });

    setLinks(newLinks);

    props.onChange(JSON.stringify(newLinks));
  };

  const onChange = (index: number, link: ExternalLinkExtended) => {
    const newLinks = produce(links, draftState => {
      draftState[index] = link;
    });

    setLinks(newLinks);

    props.onChange(JSON.stringify(newLinks));
  };

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (sourceIndex === destIndex) {
      return;
    }

    const newLinks = produce(links, draftState => {
      const link = draftState.splice(sourceIndex, 1)[0];
      draftState.splice(destIndex, 0, link);
    });

    setLinks(newLinks);

    props.onChange(JSON.stringify(newLinks));
  };

  return (
    <SubmissionFieldContainer
      className={namespace}
      mode={props.formMode}
      fieldName={props.label}
    >
      <SubmissionFieldGrayBox
        fieldName={props.label}
        liveValue={
          <LinksInputDisplay externalLinksStr={props.data.liveValue} />
        }
        submittedValue={
          props.formMode === "resubmit" ? (
            <LinksInputDisplay externalLinksStr={cachedSubmittedValue} />
          ) : (
            <LinksInputDisplay externalLinksStr={props.data.submittedValue} />
          )
        }
        finalValue={
          <LinksInputDisplay externalLinksStr={props.data.finalValue} />
        }
        mode={props.formMode}
      />
      <SubmissionFieldEditContainer
        label={props.label}
        description={
          <>
            To update the current links, copy and enter your new links in the
            URL fields below. Please ensure your links are accessible
            Amgen-wide.
          </>
        }
        submissionField={props.data}
        formMode={props.formMode}
        fieldMode={props.fieldMode}
        isRequired={props.isRequired}
      >
        <div className={`${namespace}-container`}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={props.data.fieldId.toString()}>
              {(provided, snapshot) => {
                const classNameBody = classnames(
                  `${namespace}-container-droppable`,
                  {
                    isDraggingOver: snapshot.isDraggingOver
                  }
                );

                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classNameBody}
                  >
                    {links.map((l, i) => (
                      <LinksInputItem
                        key={l.id}
                        link={l}
                        index={i}
                        fieldName={props.label}
                        onDeleteClick={onDeleteClick}
                        onChange={onChange}
                        registerValidation={registerItemsValidation}
                        showIsSensingExternal={true}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <div className={`${namespace}-container-footer`}>
            <Button
              label="Add Link"
              type="primary"
              icon={<PlusIcon />}
              iconPosition="right"
              onClick={onAddClick}
            />
            {!!links.length && props.overallConfirmation && (
              <Checkbox
                checked={isConfirmed}
                onChange={e => {
                  setIsConfirmed(e.target.checked);
                  if (e.target.checked) {
                    setConfirmationErrors([]);
                  } else {
                    setConfirmationErrors([CONF_REQ_ERROR_MSG]);
                  }
                }}
                className={`${namespace}-confirmation-checkbox`}
              >
                I have confirmed that users will be able to access the above
                link(s)
                <span className={`${namespace}-required-asterisk`}> *</span>
              </Checkbox>
            )}
            <ErrorMessages errors={confirmationErrors} />
          </div>
        </div>
      </SubmissionFieldEditContainer>
    </SubmissionFieldContainer>
  );
};

export default LinksInput;
