import React from "react";
import { KeyEventsIconProps } from "./common-props";

export const DashIcon = (props: KeyEventsIconProps) => {
  const { width = 14, height = 14, className, fill = "black" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      className={className}
    >
      <path d="M6 0H14L8 12H0L6 0Z" fill={fill} />
    </svg>
  );
};

export default DashIcon;
