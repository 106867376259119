import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "../../utils/dayjs";

// API
import { apiResponse } from "../../utils/commonMethods";
import { apiErrorMessage, noDataMessage } from "../../assets/errorMessageConstants";

import "./index.scss";

// API
import { getApiUrl } from "../../api/api-config";

// Assets
import PlusIcon from "../../assets/icons/plus-icon";
import {
  earningsInsightsNoteTypes,
  unavailableNoteText,
} from "../../assets/constants/constants-text";

// Components
import AddNoteModal from "../add-note-modal";
import { Button, Loader } from "@gitlab-rtsensing/component-library";
import Card from "../card";
import EditNoteModal from "../edit-note-modal";
import { TimeScaleData } from "../../api/get-year-and-quarter-list-dt";

// Utils
import { getOktaToken } from "../../utils/commonMethods";
import ConfirmationModal from "../confirmation-modal";

type EarningsInsightCurationProps = {
  timeScaleData: TimeScaleData;
};

const EarningsInsightCuration: React.FC<EarningsInsightCurationProps> = (props: EarningsInsightCurationProps) => {
  const { timeScaleData } = props;
  const oktaToken = getOktaToken();
  const [selectedNoteType, setSelectedNoteType] = React.useState("all");
  const [notesLoading, isNotesLoading] = React.useState(false);
  const [notesList, setNotesList] = React.useState([]);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = React.useState(false);
  const [isEditNoteModalOpen, setIsEditNoteModalOpen] = React.useState(false);
  const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] = React.useState(false);
  const [selectedNote, setSelectedNote] = React.useState({} as any); 

  // Notes Categories  
  const [ notesCategoryList, setNotesCategoryList ] = useState([]);
  const [ notesCategoryListLoader, setNotesCategoryListLoader ] = useState(false);
  const [ notesCategoryListError, setNotesCategoryListError ] = useState("");

  const handleOptionChange = (e: any) => {
    setSelectedNoteType(e.target.value);
  };

  const getNotesList = async () => {
    try {
      isNotesLoading(true);

      const res = await axios({
        method: "get",
        url: `${getApiUrl("get-user-notes")}?note_category=${selectedNoteType}`,
        headers: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
      if (res && res.data && res.data.status === "SUCCESS") {
        isNotesLoading(false);
        setNotesList(res.data.data);
      } else {
        isNotesLoading(false);
      }
      isNotesLoading(false);
    } catch (error) {
      setNotesList([]);
      isNotesLoading(false);
    }
  };

  useEffect(() => {
    getNotesList();
  }, [selectedNoteType]);

  const getNotesCategories = async() => {
    setNotesCategoryListLoader(true)
    try {
      const res = await apiResponse("get", "get-notes-categories", [], {});
      if (res) {
        if (res && res.data && res.data.data) {
          setNotesCategoryList(res.data.data)
        }
      } else {
        setNotesCategoryListError(noDataMessage)
      }
      setNotesCategoryListLoader(false)
    } catch (error) {
      setNotesCategoryListLoader(false)
      setNotesCategoryListError(apiErrorMessage)
    }
  }

  const [ noteItemForEdit, setNoteItemForEdit ] = useState({} as any); 
  const onEditNoteBtnClick = (note: any) => {
    setNoteItemForEdit(note);
    setIsEditNoteModalOpen(true);
    getNotesCategories();
  }

  const getCompanyNameList = (companyList: any, notesCategoryList:any ) => {
    let commonObjects:any = [];
    let finalArray:any = "";
    notesCategoryList && notesCategoryList.length>0 && notesCategoryList.map((item:any) => {
      if(item.category_name === 'company') {
        commonObjects = item.values.filter((objA:any)=> companyList.includes(objA.id));
      }
    }
   )
   finalArray  = commonObjects.length > 0 && commonObjects.map((item:any) => item.name).join(", ");
    return finalArray;
  }
  

  const resetNotesCategories = () => {
    setNotesCategoryList([])
    getNotesList();
    setNotesCategoryListError("");
  }

  useEffect(() => {
    (!isAddNoteModalOpen || !isEditNoteModalOpen) && getNotesCategories();
  }, [isEditNoteModalOpen, isAddNoteModalOpen])

  return (
    <>
      <div className="insight-curation-container">
        <div className="ext-note-type">NOTE TYPE</div>
        <div className="ext-note-values ops-mt-2">
          <div className="ext-note-type-radio-btn">
            {earningsInsightsNoteTypes.map((noteType: any, index:number) => {
              return (
                <span className="ext-radio-btn" key={index}>
                  <input
                    type="radio"
                    value={noteType.value}
                    checked={selectedNoteType == noteType.value}
                    onChange={handleOptionChange}
                    style={{ height: "16px", width: "16px" }}
                  />
                  <span className="ext-radio-label">{noteType.label}</span>
                </span>
              );
            })}
          </div>
          <div>
            <Button
              type="secondary"
              label="ADD NOTE"
              className="btn-selected"
              icon={<PlusIcon fill="#FFF" />}
              iconPosition="right"
              onClick={() => {
                setIsAddNoteModalOpen(true), getNotesCategories();
              }}
            ></Button>
            <div>
            {isAddNoteModalOpen &&
              <AddNoteModal
                isOpen={isAddNoteModalOpen}
                setClosedTagsModalList={setIsAddNoteModalOpen}
                notesCategory={notesCategoryList}
                resetNotesCategories={resetNotesCategories}
                notesCategoryLoader={notesCategoryListLoader}
                notesCategoryListError={notesCategoryListError}
                timeScaleData={timeScaleData}
              />}
              </div>
              <div>
              {
                isEditNoteModalOpen &&
                  <EditNoteModal
                  isOpen={isEditNoteModalOpen}
                  setClosedTagsModalList={setIsEditNoteModalOpen}
                  notesCategory={notesCategoryList}
                  resetNotesCategories={resetNotesCategories}
                  notesCategoryLoader={notesCategoryListLoader}
                  notesCategoryListError={notesCategoryListError}
                  noteItemForEdit={noteItemForEdit}
                  setIsOpenDeleteModal={setIsOpenDeleteConfirmationModal}
                  userSelectedNote={selectedNote}
                  setUserSelectedNote={setSelectedNote}
                  timeScaleData={timeScaleData}
                  />
              }
              </div>
              <div>
              {
                isOpenDeleteConfirmationModal && 
                <ConfirmationModal 
                  isOpenDeleteModal={isOpenDeleteConfirmationModal}
                  setIsOpenDeleteModal={setIsOpenDeleteConfirmationModal}
                  setClosedTagsModalList={setIsEditNoteModalOpen}
                  selectedNote={noteItemForEdit}
                  getNotesList={getNotesList} />
              }
              </div>
          </div>
        </div>
      </div>
      <div className="insight-curation-container ops-mt-4">
        {notesLoading ? (
          <div className="ext-d-flex justify-content-center">
            <Loader />
          </div>
        ) : (
          
          <Card cardHeader="Submissions" showBottomBorder={false}>
           {!notesCategoryListLoader ? <> 
              <div className="insight-table-container">
                <table className="ext-table">
                  <thead>
                    <tr className="insights-table-color-tr">
                      <th className="ext-insight-th">DATE UPDATED</th>
                      <th className="ext-insight-th" colSpan={2}>
                        NOTE CONTENTS
                      </th>
                      <th className="ext-insight-th">NOTE TYPE</th>
                      <th className="ext-insight-th">OWNER</th>
                      <th className="ext-insight-th">COMPANIES</th>
                      <th className="ext-insight-th">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody className="ext-table-body">
                    {notesList?.length > 0 &&
                      notesList?.map((note: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td className="ext-insight-th td-bold">
                              {dayjs(note.to_char).format("MM/DD/YYYY")}
                            </td>
                            <td className="ext-insight-th" colSpan={2}>
                              {note.note_details}
                            </td>
                            <td className="ext-insight-th">
                              {note.note_category}
                            </td>
                            <td className="ext-insight-th">{note.user_name}</td>
                            <td className="ext-insight-th">
                              {note.company_tag && getCompanyNameList(note.company_tag, notesCategoryList) }
                            </td>
                            <td className="ext-insight-th">
                              <Button
                                label="EDIT"
                                type="secondary"
                                className="ext-note-edit-btn"
                                onClick={() => onEditNoteBtnClick(note)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {notesList?.length === 0 && (
                <div className="ext-not-available-text">
                  {unavailableNoteText}
                </div>
              )}
            </>: <></>}
          </Card>
        )}
      </div>
    </>
  );
}

export default EarningsInsightCuration;
