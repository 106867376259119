import React, { useContext, useEffect, useMemo, useState } from "react";
import PermissionsWrapper from "common-components/permissions-wrapper/permissions-wrapper";
import { PermissionsContext } from "global/permissions";
import "./KeyEventsPageHeader.scss";
import pages from "pages/pages";
import ToggleButton from "common-components/toggle-button/ToggleButton";
import useLimitedView from "global/use-limited-view";
import Button from "common-components/button/Button";
import {
  Badge,
  PlusIcon
} from "@opsdti-global-component-library/amgen-design-system";
import CalendarKeys from "components/calendar-keys/CalendarKeys";
import { useNavigate } from "react-router-dom";
import TenantSelector from "components/tenant-selector/TenantSelector";
import { DateRangePickerSimple } from "common-components";
import { Dayjs } from "dayjs";
import { useCalendarModeHelper } from "global/use-key-events-calendar-mode-helper";
import { exportMilestonesToCSV } from "./exportMilestonesToCSV";
import { useCalendarContext } from "../CalendarContext";
import { getCalendarBoard, getMilestoneCalendarKeys } from "api";
import { useQuery } from "@tanstack/react-query";
import { KeyEventsActivityLog } from "components/activity-log/key-events-activity-log/KeyEventsActivityLog";
import Drawer from "common-components/drawer/Drawer";
import FilterAccordion, {
  FilterAccordionCheckboxType
} from "../../../components/key-events/filter/FilterAccordion";

const namespace = "rts-pa-key-events-page-header";

export type Props = {
  dateRange: [Dayjs, Dayjs] | [];
  onDateRangeChange: (dateRange: [Dayjs, Dayjs] | []) => void;
  onReorderSave: (() => void) | undefined;
};

export default function KeyEventsPageHeader(props: Props): JSX.Element {
  const {
    calendarRows: calendarRowsData,
    calendarFilters,
    setCalendarFilters
  } = useCalendarContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [badgeCount, setBadgeCount] = useState(0);
  const calendarMode = useCalendarModeHelper();
  const perms = useContext(PermissionsContext).keyEvents;

  const { isLimitedView, toggleLimitedView } = useLimitedView(pages.keyEvents);
  const navigate = useNavigate();

  const { data: apiCalendarKeys, status: apiCalendarKeysStatus } = useQuery(
    ["calendar-keys"],
    getMilestoneCalendarKeys
  );

  const { data: apiCalendarBoard, status: apiCalendarBoardStatus } = useQuery(
    ["calendar-board"],
    () => getCalendarBoard(isLimitedView)
  );

  const onResetClick = () => {
    let keyOptions: FilterAccordionCheckboxType[] = [];
    if (apiCalendarKeys) {
      keyOptions = apiCalendarKeys.keys.map(key => ({
        id: key.id.toString(),
        label: key.label,
        isChecked: false
      }));
    }

    let rowOptions: FilterAccordionCheckboxType[] = [];
    if (apiCalendarBoard) {
      rowOptions = apiCalendarBoard.rows.map(row => ({
        id: row.id.toString(),
        label: row.name,
        isChecked: false
      }));
    }

    setCalendarFilters({
      ...calendarFilters,
      keys: keyOptions,
      rows: rowOptions
    });
    navigate(pages.keyEvents.go());
  };

  useMemo(() => {
    if (calendarFilters.keys && calendarFilters.rows) {
      const countChecked = (array: FilterAccordionCheckboxType[] = []) => {
        return array.filter(item => item?.isChecked).length;
      };

      const totalChecked =
        countChecked(calendarFilters.keys) + countChecked(calendarFilters.rows);

      setBadgeCount(totalChecked);
    }
  }, [calendarFilters]);

  useEffect(() => {
    let keyOptions: FilterAccordionCheckboxType[] = [];
    if (apiCalendarKeys) {
      keyOptions = apiCalendarKeys.keys.map(key => ({
        id: key.id.toString(),
        label: key.label,
        isChecked: false
      }));
    }

    let rowOptions: FilterAccordionCheckboxType[] = [];
    if (apiCalendarBoard) {
      rowOptions = apiCalendarBoard.rows.map(row => ({
        id: row.id.toString(),
        label: row.name,
        isChecked: false
      }));
    }

    setCalendarFilters({
      ...calendarFilters,
      keys: keyOptions,
      rows: rowOptions
    });
  }, [apiCalendarKeys, apiCalendarBoard]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={namespace}>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <TenantSelector />
        </div>
        <div className={`${namespace}-right-container`}>
          <PermissionsWrapper permissions={perms.edit}>
            <ToggleButton
              firstLabel="FULL VIEW"
              secondLabel="LIMITED VIEW"
              checked={isLimitedView}
              onClick={toggleLimitedView}
            />
          </PermissionsWrapper>
        </div>
      </div>
      <KeyEventsActivityLog />
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <PermissionsWrapper permissions={perms.edit}>
            <Button
              disabled={calendarMode.isRearrangeMode}
              text="Add Row"
              icon={<PlusIcon height={14} />}
              onClick={() => navigate(pages.keyEvents.addItemModal.go())}
            />
            {calendarMode.isRearrangeMode ? (
              <Button text="Save" onClick={props.onReorderSave} />
            ) : (
              <Button
                text="Rearrange"
                type="secondary"
                onClick={() => {
                  navigate(pages.keyEvents.rearrange.go());
                }}
              />
            )}
          </PermissionsWrapper>
          <Badge count={badgeCount}>
            <Button
              text="filters"
              type="secondary"
              disabled={
                apiCalendarKeysStatus === "loading" ||
                apiCalendarBoardStatus === "loading"
              }
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            />
          </Badge>
          {badgeCount > 0 && (
            <Button
              type="link"
              text="reset filters"
              onClick={() => onResetClick()}
            />
          )}
          <DateRangePickerSimple
            onChange={props.onDateRangeChange}
            value={props.dateRange}
            disabled={calendarMode.isRearrangeMode}
          />
          {calendarMode.isRearrangeMode && (
            <PermissionsWrapper permissions={perms.edit}>
              <Button
                text="cancel rearrange"
                type="link"
                onClick={() => {
                  navigate(pages.keyEvents.go());
                }}
              />
            </PermissionsWrapper>
          )}
        </div>
        <div>
          <PermissionsWrapper permissions={perms.edit}>
            <Button
              disabled={calendarMode.isRearrangeMode}
              text="Export to CSV"
              type="secondary"
              onClick={() =>
                exportMilestonesToCSV(calendarRowsData, apiCalendarKeys)
              }
            />
          </PermissionsWrapper>
        </div>
      </div>
      <div className={`${namespace}-row`}>
        <div className={`${namespace}-left-container`}>
          <CalendarKeys />
          <Drawer
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            title="FILTERS"
          >
            <div className={`${namespace}-drawer-datepicker`}>
              <DateRangePickerSimple
                label="Date"
                onChange={props.onDateRangeChange}
                value={props.dateRange}
                disabled={calendarMode.isRearrangeMode}
              />
            </div>
            <FilterAccordion
              filterType="key"
              status={apiCalendarKeysStatus}
              title="Calendar Keys"
              autoUpdater={{
                item: calendarFilters,
                setItem: setCalendarFilters,
                propExpression: filter => filter.keys
              }}
              options={calendarFilters.keys}
            />
            <FilterAccordion
              filterType="row"
              status={apiCalendarBoardStatus}
              title="Row Name"
              autoUpdater={{
                item: calendarFilters,
                setItem: setCalendarFilters,
                propExpression: filter => filter.rows
              }}
              options={calendarFilters.rows}
            />
            <Button
              text="Reset Filters"
              type="link"
              onClick={() => onResetClick()}
            />
          </Drawer>
        </div>
      </div>
    </div>
  );
}
