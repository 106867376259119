import React, { useContext } from 'react';
import "./index.scss";
import { Modal, Button, Text, ThemeContext } from '@opsdti-global-component-library/amgen-design-system';

type ClearAllConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onClearAll: () => void;
};

export const ClearAllConfirmationModal: React.FC<ClearAllConfirmationModalProps> = ({ isOpen, onClose, onClearAll }) => {
  const { theme } = useContext(ThemeContext);
  const { colorBorderSecondary } = theme?.token as any;
  
  return (
    <Modal open={isOpen} onCancel={onClose} title="Clear All Articles">
      <div>
        <Text>Are you sure you want to clear all of your selected articles?</Text>
      </div>
      <div className="ext-modal-footer" style={{borderTop: `1px solid ${colorBorderSecondary}`, paddingTop: '8px'}}>
        <Button text="CANCEL" type="secondary" style={{ width: "188px", marginRight: "8px"}} onClick={onClose} />
        <Button text="CLEAR ALL ARTICLES" type="primary" style={{ width: "188px"}} onClick={onClearAll} />
      </div>
    </Modal>
  );
};

