import React from "react";
import {
  Select,
  SelectProps,
  SelectValue
} from "@opsdti-global-component-library/amgen-design-system";
import { Label } from "common-components";
import "./SelectSimple.scss";
export type { Option } from "@opsdti-global-component-library/amgen-design-system";

type SelectValueType = SelectValue | SelectValue[];

export type Props<V extends SelectValueType> = {
  label?: string;
  required?: boolean;
} & SelectProps<V>;

const namespace = "rts-curation-select-simple";

export function SelectSimple<V extends SelectValueType>(props: Props<V>) {
  return (
    <div className={namespace}>
      <Label requiredAsterisk={props.required}>{props.label}</Label>
      <Select
        {...props}
        placeholder={props.loading ? "Loading..." : props.placeholder}
      />
    </div>
  );
}

export default SelectSimple;
