import React, { useState } from "react";
import "./index.scss";
import Accordion from "../ui-componnets/accordion/index";
import "../../assets/css/bootstrap.scss";
import { Title, CaretRightIcon, CaretDownIcon } from "@opsdti-global-component-library/amgen-design-system";

type Props = {
  cardHeader?: string;
  totalFilterCount?: number;
  children: JSX.Element | JSX.Element[];
};

const CardFilter: React.FC<Props> = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const { totalFilterCount = 0, cardHeader } = props;

  return (
    <div className="ext-card-filter card">
      <Accordion
        header={
          <div
            className={"ext-card-filter-header"}
            onClick={() => {
              setOpen(!open);
            }}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            <div className="ext-card-header-txt">
              {!open ? (
                <CaretRightIcon className="ext-filter-icon" />
              ) : (
                <CaretDownIcon className="ext-filter-icon" />
              )}
              {cardHeader && <Title level={3}>{cardHeader}</Title>}
            </div>
            {totalFilterCount !== 0 && (
              <div className="ext-filter-btn cursor-pointer filter ext-d-lg-none ext-col-7">
                {totalFilterCount} Applied
              </div>
            )}
          </div>
        }
      >
        <>
          <div
            className="ext-card-filter-body card-body ext-d-lg-none"
            id="example-collapse-text"
          >
            <div className="ext-border-top ext-border-collapse" />
            {props.children}
          </div>
        </>
      </Accordion>
      {cardHeader && <div className="ext-border-div" />}
      <div className="ext-card-filter-body-x card-body">{props.children}</div>
    </div>
  );
};

export default CardFilter;
