import React, { useContext } from "react";
import { BaseActivityLog } from "../base-activity-log/BaseActivityLog";
import {
  ActivityLogDataPayload,
  getKeActivityLogData,
  getKeActivityLogDropdownValuesData
} from "api/activity-log";
import { PermissionsContext } from "global/permissions";
import { getKeyEventsId } from "utils/multitenancy";
import pages from "pages/pages";

export const KeyEventsActivityLog = () => {
  const perms = useContext(PermissionsContext);

  const workstream = "Prioritized Agenda";
  const activityType = "F12Q";
  const entityId = getKeyEventsId();
  const namespace = "rts-ke-activity-log";
  const hasPerms = entityId === "1" && perms.keyEvents.activityLog;
  // TODO: Update the defaultActivityLogFilters to match the actual default filters for Key Events
  const defaultActivityLogFilters: ActivityLogDataPayload = {
    workstream: workstream,
    activityType: activityType,
    filterList: [
      { filterKey: "item_id", filterValue: "All" },
      { filterKey: "key", filterValue: "All" },
      { filterKey: "timeline", filterValue: "Last 60 Days" }
    ]
  };

  return (
    <BaseActivityLog
      workstream={workstream}
      activityType={activityType}
      namespace={namespace}
      hasPerms={hasPerms}
      defaultActivityLogFilters={defaultActivityLogFilters}
      getDataFn={getKeActivityLogData}
      getDropdownDataFn={getKeActivityLogDropdownValuesData}
      isLimitedViewPage={pages.keyEvents}
    />
  );
};
