import React, { useState, useEffect, useContext, useRef } from "react";
import { Loader } from "@gitlab-rtsensing/component-library";
import axios , { CancelTokenSource } from "axios";
import "../oncology/index.scss";

// Assets
import {
  apiErrorMessage,
  noDataMessage,
} from "../../assets/errorMessageConstants";
// import FilterIcon from "../../assets/icons/filter-icon";

// Components
// import Accordion from "../../components/accordian";
import ArticleCard from "../../components/article-card-dscu";
import Card from "../../components/card";
import CardFilter from "../../components/cards-filter";
import DateSelector from "../../components/date-picker";
import ErrorMessage from "../../components/error-message";
import FilterDropdown from "../../components/filter-dropdown";
// import { Pagination } from "../../components/pagination";
import RequestPage from "../../components/request-access-page";
import { 
  Button,
  Title,
  Pagination,
  // Breadcrumb,
  Collapse,
  Switch,
  Select,
  Paragraph,
  SearchIcon,
  Input
} from '@opsdti-global-component-library/amgen-design-system'

// Utils
import { SEARCH_TEXT, SWITCH_SUMMARIES_TEXT, SELECT_OPTIONS} from "../news-consts";
import { AppContext } from "../../utils/app-context";
import {
  apiResponse,
  lastApiResponse,
} from "../../utils/commonMethods";
import { 
  getFormattedTagListWithFilter,
  filterCountFn,
} from "../news-utils";
import {
  Tag,
  Article,
  NewsCurrentlyFilteredList,
  FilterApiResponseData
} from '../news-types'
import dayjs, { Dayjs } from "../../utils/dayjs";
import { useLocation } from "react-router-dom";

const PAGE_LIMIT = 50;
const News: React.FC = () => {
  const { authData } = useContext(AppContext);

  const [filterList, setFilterList] = useState<FilterApiResponseData>({
    company: [],
    publisher: [],
    product: [],
    topic: [],
    corex_topic: [],
    kiq: [],
    av_company: []
  });
  const [isFilterListLoading, setIsFilterListLoading] =
  useState<boolean>(false);
  const [filterListError, setFiltersListError] = useState("");

  const [sortOrder, setSortOrder] = useState<string>("desc");
  const [page, setPage] = useState<number>(1);
  const [upperLimit, setUpperLimit] = useState<number>(0);
  const [lowerLimit, setLowerLimit] = useState<number>(0);

  const [newsList, setNewsList] = useState<Array<Article>>();
  const [newsListError, setNewsListError] = useState("");
  const [newsListLoading, setNewsListLoading] = useState<boolean>(false);

  const [newsCount, setNewsCount] = useState<number | null>(null);
  const [paginationDate, setPaginationDate] = useState<any>("");
  const [totalFilterCount, setTotalFilterCount] = useState<number>(0);

  const [endDate, setEndDate] = useState<Dayjs>(dayjs());
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().subtract(89, 'days'));
  let cancelTokenSource = useRef<CancelTokenSource | null>(null);

  const [showArticleSummary, setShowArticleSummary] = useState(true);

  const [currentlyFilteredList, setCurrentlyFilteredList] = useState<NewsCurrentlyFilteredList>({
    publisher: [],
    company: [],
    product: [],
    topic: [],
    corex_topic: [],
    kiq: [],
    av_company: []
  });

  const currentLimit =
    upperLimit < (newsCount || 0) ? upperLimit : newsCount;
  const todayDate: Dayjs = dayjs();

  //News specific
  const [searchedValue, setSearchedValue] = useState<string>("");
  const [allTopicList, setAllTopicList] = useState<{topic: any[], corex_topic: any[]}>({topic: [], corex_topic: []});

  const checkFilterBox = (value: {
    field: string;
    name: string;
    category_name: string;
    isChecked: boolean;
  }) => {
    if(value.field === 'topic'){
      // Need to lookup which category the topic belongs to, "topic" or "corex_topic"
      if(filterList.corex_topic.find((ele) => ele.name === value.name)){
        value.field = 'corex_topic';
      }
    }


    const currentlyFilteredListKey = value.field.toLowerCase() as keyof NewsCurrentlyFilteredList
    const filterObject: Array<string> = currentlyFilteredList[currentlyFilteredListKey];
    let updatedFilterObject: any[] = [];

    if (value.isChecked) {
      filterObject.push(value.name);
      updatedFilterObject = filterObject;
    } else {
      updatedFilterObject = filterObject.filter((item: any) => {
        if (item !== value.name) {
          return true;
        }
        return false;
      });
    }

    setCurrentlyFilteredList((prevState: any) => {
      prevState[currentlyFilteredListKey] = updatedFilterObject;
      return { ...prevState };
    });
    setPage(1);
  };

  const getFilterList = async () => {
    setIsFilterListLoading(true);
    const payload = {
      filters: [
        'company',
        'product',
        'topic',
        'corex_topic',
        'kiq',
        'av_company',
      ]
    }

    try {
      const res = await apiResponse("post", "get-filters", [], payload);
      if (res?.data.data) {
        setFilterList(res.data.data);
        setAllTopicList({topic: res.data.data.topic, corex_topic: res.data.data.corex_topic});
      }
      setIsFilterListLoading(false);
      getPublisherList()
      getCompanyList()
    } catch (error) {
      setIsFilterListLoading(false);
      setFiltersListError(apiErrorMessage);
    }
  };

  const getArticleList = async (
    page: number,
    startDateSelected: Dayjs,
    endDateSelected: Dayjs
  ) => {
    setNewsList([]);
    setNewsListLoading(true);
    setNewsListError("");

    const usersTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startDateLocal = startDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD")
    const endDateLocal = endDateSelected.locale('en-gb').tz(usersTimeZone).format("YYYY-MM-DD");

    const payload = {
      sort_by_value: sortOrder,
      start_date: startDateLocal,
      end_date: endDateLocal,
      page_offset: page - 1,
      limit: PAGE_LIMIT,
      filters: {
        publisher: currentlyFilteredList.publisher,
        company: currentlyFilteredList.company,
        product: currentlyFilteredList.product,
        topic: currentlyFilteredList.topic,
        corex_topic: currentlyFilteredList.corex_topic,
        kiq: currentlyFilteredList.kiq,
        avCompany: currentlyFilteredList.av_company,
      }
    };
    try {
      const res = await lastApiResponse("post", "get-news-with-tags", cancelTokenSource, payload);
      if (res.data.status === "SUCCESS" && 
          (res.data.data.response && res.data.data.response.length !== 0) &&
          (res.data.data.count !== 0 && res.data.data.count !== null)) {
        setNewsList(res.data.data.response);
        setNewsListError("");
        setPaginationDate(res.data.data.ingested_date);
        setNewsCount(res.data.data.count);
        setLowerLimit((page - 1) * PAGE_LIMIT + 1);
        setUpperLimit(page * PAGE_LIMIT);
      } else {
        setNewsListError(noDataMessage);
      }
      setNewsListLoading(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setNewsList([]);
        setNewsListLoading(false);
        setNewsListError(apiErrorMessage);
      }
    }
  };

  const onDateChange = (data: any) => {
    setPage(1)
    data.fieldName === "fromDate"
      ? setStartDate(data?.data)
      : setEndDate(data?.data);
  };

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData)?.length > 0 &&
      authData?.externalAuthorization?.news
    ) {
      getArticleList(page, startDate, endDate);
      setTotalFilterCount(filterCountFn(currentlyFilteredList));
    }
  }, [
    sortOrder,
    page,
    currentlyFilteredList.publisher.length,
    currentlyFilteredList.company.length,
    currentlyFilteredList.product.length,
    currentlyFilteredList.topic.length,
    currentlyFilteredList.corex_topic.length,
    currentlyFilteredList.kiq.length,
    currentlyFilteredList.av_company.length,
    startDate,
    endDate,
  ]);

  const location = useLocation()

  useEffect(() => {
    if (
      authData &&
      Object.keys(authData)?.length > 0 &&
      authData?.externalAuthorization?.news
    ) {
      if(location.state?.callingPath === "/intelligence") {
        checkFilterBox({
          field: "company",
          name: "AMGN",
          category_name: "",
          isChecked: true
        })
      }
      getFilterList()
    }
  }, []);

  const resetFilter = () => {
    setCurrentlyFilteredList({ 
      publisher: [], 
      company: [],
      product: [],
      topic: [],
      corex_topic: [],
      kiq: [],
      av_company: []
    });
    setPage(1);
    setStartDate(dayjs().subtract(89, 'days'));
    setEndDate(todayDate);
    filterTopicList({preventDefault: () => {}, target: {value: ""}})
  };

  const formatFilterCount = (filterCount: number) => {
    if(filterCount === 0) return ""
    return `(${filterCount})`
  }

  const filterTopicList = (e: any) => {
    e.preventDefault();
    // When search field is cleared
    if(!e.target.value) {
      setFilterList((prevState: any) => {
        prevState['topic'] = allTopicList.topic;
        prevState['corex_topic'] = allTopicList.corex_topic;
        return { ...prevState };
      });
      setSearchedValue("")
    }
    if (e?.target?.value?.length > 0) {
      const resultTopicList =  allTopicList.topic.filter((topic:any) => {
        return topic.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      const resultTopicListCorex =  allTopicList.corex_topic.filter((topic:any) => {
        return topic.name.toLowerCase().includes(e.target.value.toLowerCase());
      });
      setFilterList((prevState: any) => {
        prevState['topic'] = resultTopicList;
        prevState['corex_topic'] = resultTopicListCorex;
        return { ...prevState };
      });
      setSearchedValue(e.target.value)
    };
  }

  const getPublisherList = async () => {
    setIsFilterListLoading(true);
    try {
      const res = await apiResponse("get", "get-published-list", [], {});
      if (res?.data.data) {
        setFilterList((prevState: any) => {
          prevState['publisher'] = res.data.data;
          return { ...prevState };
        });
      } else {
        setFiltersListError(noDataMessage);
      }
      setIsFilterListLoading(false);
    } catch (error) {
      setIsFilterListLoading(false);
      setFiltersListError(apiErrorMessage);
    }
  };

  const getCompanyList = async () => {
    setIsFilterListLoading(true);
    try {
      const res = await apiResponse("get", "get-company-list", [], {});
      if (res?.data.data) {
        setFilterList((prevState: any) => {
          prevState['company'] = res.data.data;
          return { ...prevState };
        });
      } else {
        setFiltersListError(noDataMessage);
      }
      setIsFilterListLoading(false);
    } catch (error) {
      setIsFilterListLoading(false);
      setFiltersListError(apiErrorMessage);
    }
  };

  let items = [
    {
      children: <FilterDropdown
        label="company"
        isLoading={isFilterListLoading}
        options={filterList.company}
        checkedOptions={currentlyFilteredList.company}
        onClickCheckbox={checkFilterBox}
        error={filterListError}
      />,
      key: '2',
      label: `Company ${formatFilterCount(currentlyFilteredList['company'].length)}`
    }
  ]

  if(filterList['publisher']?.length > 0){
    items.unshift({
      children: <FilterDropdown
        label="publisher"
        options={filterList.publisher}
        isLoading={isFilterListLoading}
        checkedOptions={currentlyFilteredList.publisher}
        onClickCheckbox={checkFilterBox}
        error={filterListError}
      />,
      key: '1',
      label: `Publisher ${formatFilterCount(currentlyFilteredList['publisher'].length)}`
    })
  }
  if(authData?.externalAuthorization?.products){
    items.push({
      children: <FilterDropdown
        label="product"
        options={filterList.product}
        isLoading={isFilterListLoading}
        checkedOptions={currentlyFilteredList.product}
        onClickCheckbox={checkFilterBox}
        error={filterListError}
      />,
      key: '3',
      label: `Product ${formatFilterCount(currentlyFilteredList['product'].length)}`
    })
  }
  if(authData?.externalAuthorization?.kiq){
    items.push({
      children: <FilterDropdown
        label="kiq"
        options={filterList.kiq}
        isLoading={isFilterListLoading}
        checkedOptions={currentlyFilteredList.kiq}
        onClickCheckbox={checkFilterBox}
        error={filterListError}
      />,
      key: '4',
      label: `KIQ ${formatFilterCount(currentlyFilteredList['kiq'].length)}`
    })
  }
  if(authData?.externalAuthorization?.topics){
    items.push({
      children: <>
        <Input
          className="topic-search"
          placeholder="Search Topics"
          value={searchedValue}
          onChange={(e: any) => filterTopicList(e)}
          suffixContent={<SearchIcon width={16} height={16} className='topic-search-icon' fill="rgba(0, 0, 0, 0.45)"/>}
        />
        <FilterDropdown
          label="topic"
          options={[...filterList.corex_topic, ...filterList.topic]}
          isLoading={isFilterListLoading}
          checkedOptions={[...currentlyFilteredList.topic, ...currentlyFilteredList.corex_topic]}
          onClickCheckbox={checkFilterBox}
          error={filterListError}
        />
      </>,
      key: '5',
      label: `Topic ${formatFilterCount([...currentlyFilteredList.topic, ...currentlyFilteredList.corex_topic].length)}`
    })
  }
  if(authData?.externalAuthorization?.avCompany){
    items.push({
      children: <FilterDropdown
        label="av_company"
        options={filterList.av_company}
        isLoading={isFilterListLoading}
        checkedOptions={currentlyFilteredList.av_company}
        onClickCheckbox={checkFilterBox}
        error={filterListError}
      />,
      key: '6',
      label: `Ventures ${formatFilterCount(currentlyFilteredList['av_company'].length)}`
    })
  }

  return authData && authData?.externalAuthorization?.news ? (
    <div className="ext-oncology">
      <Title level={1}>News Article Search</Title>
      <div className="ext-news-container">
        <div className="ext-news-left-panel">
            <CardFilter
              cardHeader="Filter Articles"
              totalFilterCount={totalFilterCount}
            >
              <div className="ext-filter-element-container">
                <DateSelector
                  selectedDate={startDate}
                  label="PUBLISHED BETWEEN"
                  verbageText="Select publish date"
                  minDate={dayjs().subtract(1, 'years')}
                  maxDate={endDate}
                  onChange={(date) =>
                    onDateChange({
                      data: date,
                      fieldName: "fromDate",
                    })
                  }
                />
                <DateSelector
                  selectedDate={endDate}
                  label="AND"
                  minDate={dayjs().subtract(1, 'years')}
                  maxDate={todayDate}
                  onChange={(date) =>
                    onDateChange({ data: date, fieldName: "toDate" })
                  }
                />
                <div style={{minHeight: 24, width: '100%'}}/>
                <Collapse
                  defaultActiveKey={1}
                  items={items}
                />
              </div>
              <div className="ext-reset-filters">
                <Button
                  text="RESET FILTERS"
                  type="link"
                  onClick={() => resetFilter()}
                />
              </div>
            </CardFilter>
        </div>
        {
          <div className="ext-news-right-panel">
            <Card cardHeader="Articles" newsCard={true}>
              <div className="ext-article-container">
                <div className="ext-article-sorting-wrapper">
                  <Paragraph className="ext-article-card-note">
                     {SEARCH_TEXT}
                  </Paragraph>
                  <div className="ext-card-toggle-options">
                    <div className="ext-show-summary-parent">
                      <Switch
                        checked={showArticleSummary}
                        onChange={() => setShowArticleSummary(!showArticleSummary)}
                        inlineText={SWITCH_SUMMARIES_TEXT}
                      />      
                    </div>
                    <Select
                      options={SELECT_OPTIONS}
                      defaultValue="desc"
                      onChange={(val) => setSortOrder(val)}
                    />
                </div>
              </div>
              <div className="ext-article-list-wrapper">
              {newsListLoading && (
                    <div className="ext-news-loader">
                      <Loader />
                    </div>
                  )}
                  {newsListError && (
                    <ErrorMessage isIcon={false} message={newsListError} />
                  )}
                  {!newsListLoading &&
                    !newsListError &&
                    newsList &&
                    newsList.map((article: any, index: number) => {
                      const isEven = (index % 2 === 0)

                      // company_names  : 01FKKJ5HDJBRVPRN1E8XXYFX02$$Novartis AG$$NVS << Get "Novartis AG"
                      // company_tickers: ["NVS"]
                      const company_name_split = article?.company_names?.map((name: string) => name.split("$$")[1]);
                      const tags: Array<Tag> = getFormattedTagListWithFilter(
                        {
                          "publisher": article.publisher_names, // Should always be null, but the key needs to exist
                          "company": article.company_tickers,
                          "product": article.product_names,
                          "topic": article.topic_names,
                          "kiq": article.kiq_names,
                          "av_company": article.av_company_names,
                          "corex_topic": article.corex_topic_names,
                        },
                        {
                          company: company_name_split,
                        },
                        currentlyFilteredList,
                        checkFilterBox,
                      )

                      return (
                        <ArticleCard
                          key={index}
                          redirectUrl={article.url}
                          isEven={isEven}
                          title={article.title}
                          articleId={article.article_id}
                          publishDate={article.published}
                          publishSource={article.source_name}
                          tagList={tags}
                          checkboxFlag={false}
                          trashFlag={false}
                          summary={showArticleSummary ? article.article_summary : null}
                          ellipsisRequired={true}
                        />
                      );
                    })}
              </div>

              <div className="ext-article-pagination">
                  <div className="pagination-container">
                    {!newsListLoading && newsList?.length !== 0 &&
                      <div className="ext-article-pagination-wrapper">
                        <Paragraph>
                          Last update at {paginationDate?.replace("T", "T on")}
                        </Paragraph>
                        <div>
                          <Pagination
                            showSizeChanger={false}
                            showTotal={(total) => `${lowerLimit} - ${currentLimit} of ${newsCount} items`}
                            current={page}
                            total={newsCount || 0}
                            pageSize={PAGE_LIMIT}
                            onChange={(page: number) => setPage(page)}
                            responsive={true}
                          />
                        </div>
                      </div>
                      }
                  </div>
                </div>
              </div>
            </Card>
          </div>
        }
      </div>
    </div>
  ) : (
    <>{authData && <RequestPage />}</>
  );
};

export default News;
