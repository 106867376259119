import React from "react";
import "./Unauthorized.scss";
import { Button, Text } from "common-components";
import LockIcon from "icons/lock";

const namespace = "rts-pa-agenda-unauthorized-page";

export default function UnauthorizedPage(): JSX.Element {
  return (
    <div className={namespace}>
      <div className={`${namespace}-content`}>
        <LockIcon color={"#75A8DD"} />
        <Text strong>
          You don&apos;t currently have access to a Prioritized Agenda <br />
          or Key Events Calendar. If you feel this is in error, click <br />
          &quot;Contact Us&quot; on the bottom right of the screen.
        </Text>
        <Button
          text="Sensing Home"
          type="secondary"
          onClick={() => {
            window.location.href = `${window.location.origin}/home`;
          }}
        />
      </div>
    </div>
  );
}
