import React, { useEffect, useState } from "react";
import "./UpsertCalendarModalCalendarKeysContainer.scss";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import {
  PlusIcon,
  Button
} from "@opsdti-global-component-library/amgen-design-system";
import produce from "immer";
import {
  DragDropContext,
  Droppable,
  OnDragEndResponder
} from "react-beautiful-dnd";
import classnames from "classnames";
import { CheckboxSimple } from "common-components/";
import UpsertCalendarModalCalendarKey from "../calendar-key/UpsertCalendarModalCalendarKey";
import { MilestoneCalendarKey } from "api";

const namespace = "rts-pa-upsert-calendar-modal-calendar-keys-container";

export type Props<T extends AutoUpdaterType> = {
  className?: string;
  autoUpdater: AutoUpdater<T>;
  validator: FieldValUtils;
  confirmation?: boolean;
  labelMaxLength?: number;
};

export const UpsertCalendarModalCalendarKeysContainer = <
  T extends AutoUpdaterType
>(
  props: Props<T>
): JSX.Element => {
  const { value: keys, onChange } = useAutoUpdater<MilestoneCalendarKey[], T>(
    props.autoUpdater
  );
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(true);

  useEffect(() => {
    //validate Externalkeys have Id, or throw a console error
    for (const key of keys) {
      if (!key.id) {
        // eslint-disable-next-line no-console
        console.error(
          "UpsertCalendarModalCalendarKeysContainer requires all `CalendarKeys` items to have an id"
        );
        break;
      }
    }

    if (isInitialized) {
      setIsConfirmed(false);
    } else if (!isInitialized && keys.length) {
      setIsInitialized(true);
    }
  }, [keys]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddClick = () => {
    const newKeys = produce(keys, draftState => {
      draftState.push({
        id: 0,
        label: "",
        color: "#E377C2",
        type: "custom",
        icon: "circle",
        isDeletable: true,
        isHiddenOnLegend: false,
        order: draftState.length + 1
      });
    });

    onChange(newKeys);
    setIsInitialized(true);
  };

  const onDeleteClick = (i: number) => {
    const newKeys = produce(keys, draftState => {
      draftState.splice(i, 1);
    });

    onChange(newKeys);
  };

  const onDragEnd: OnDragEndResponder = result => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    if (sourceIndex === destIndex) {
      return;
    }

    const newKeys = produce(keys, draftState => {
      const key = draftState.splice(sourceIndex, 1)[0];
      draftState.splice(destIndex, 0, key);

      let order = 1;
      for (const key of draftState) {
        key.order = order++;
      }
    });

    onChange(newKeys);
  };

  return (
    <div className={namespace}>
      <div className={`${namespace}-container`}>
        {/* create passable-in droppableId if ever needing to render 2 at once */}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={namespace}>
            {(provided, snapshot) => {
              const classNameBody = classnames(
                `${namespace}-container-droppable`,
                {
                  isDraggingOver: snapshot.isDraggingOver
                }
              );

              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classNameBody}
                >
                  {keys.map((key, i) => (
                    <UpsertCalendarModalCalendarKey
                      type={key.type}
                      isDeletable={key.isDeletable}
                      order={key.order}
                      key={key.id}
                      id={key.id}
                      title={`Key ${i + 1}`}
                      autoUpdater={{
                        item: props.autoUpdater.item,
                        propExpressionLabel: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as MilestoneCalendarKey[]
                          )[i].label,
                        propExpressionColor: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as MilestoneCalendarKey[]
                          )[i].color,
                        propExpressionIcon: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as MilestoneCalendarKey[]
                          )[i].icon,
                        propExpressionIsHiddenOnLegend: x =>
                          (
                            props.autoUpdater.propExpression(
                              x
                            ) as MilestoneCalendarKey[]
                          )[i].isHiddenOnLegend,
                        setItem: props.autoUpdater.setItem
                      }}
                      autoUpdaterIndex={i}
                      onDeleteClick={onDeleteClick}
                      validator={props.validator}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={`${namespace}-footer`}>
        <Button
          text="Add Key"
          type="primary"
          icon={<PlusIcon width={14} height={14} />}
          onClick={onAddClick}
        />
        {props.confirmation && (
          <CheckboxSimple
            label="I have confirmed that users will be able to access the above key(s)"
            errorLabel="Confirmation"
            checked={isConfirmed}
            onChange={setIsConfirmed}
            validator={props.validator}
            required={true}
          />
        )}
      </div>
    </div>
  );
};

export default UpsertCalendarModalCalendarKeysContainer;
