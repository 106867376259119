import React, { useCallback, useEffect } from "react";
import "./Select.scss";
import {
  AutoUpdater,
  AutoUpdaterType,
  useAutoUpdater
} from "global/use-auto-updater";
import { FieldValUtils, ValidationError } from "global/use-validator";
import classNames from "classnames";
import {
  SelectSimple,
  Option,
  Props as SelectSimpleProps
} from "common-components/select-simple/SelectSimple";
import { SelectValue } from "@opsdti-global-component-library/amgen-design-system";
import { AdvancedTooltip } from "api";

type SelectedOptionsType<V extends SelectValue> = V | V[];

export type GenericOption<V extends SelectValue> = Omit<Option, "value"> & {
  value: V;
};

type Props<T extends AutoUpdaterType, V extends SelectValue> = Omit<
  SelectSimpleProps<SelectedOptionsType<V>>,
  "options"
> & {
  options?: GenericOption<V>[];
  autoUpdater: AutoUpdater<T>;
  validator?: FieldValUtils;
  errorLabel?: string;
  tooltip?: AdvancedTooltip;
};

const namespace = "rts-pa-select";

export function Select<T extends AutoUpdaterType, V extends SelectValue>(
  props: Props<T, V>
) {
  const { autoUpdater, ...restProps } = props;
  const { id, value, onChange } = useAutoUpdater<SelectedOptionsType<V>, T>(
    props.autoUpdater,
    props.id
  );

  const validate = useCallback(
    (val: SelectedOptionsType<V>) => {
      if (!props.validator) {
        return;
      }

      const errors: ValidationError[] = [];

      if (props.required && !val) {
        let hasError = false;
        if (Array.isArray(val)) {
          hasError = !val.length;
        } else {
          hasError = !val;
        }

        if (hasError) {
          const fieldName =
            props.errorLabel || props.label || props.placeholder;

          errors.push({
            fieldError: `${fieldName || "This field"} is required.`,
            fullError: `${fieldName || id} is required.`
          });
        }
      }

      props.validator.setErrors(id, errors);
    },
    [props.required, props.validator] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!props.validator) {
      return;
    }

    const unregister = props.validator.registerValidation(id, () =>
      validate(value)
    );

    return () => {
      unregister();
    };
  }, [value, validate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeWrapper = (value: SelectedOptionsType<V>) => {
    onChange(value);
    validate(value);
  };

  const errors = props.validator?.errors.get(id) || [];
  const className = classNames(namespace, props.className, {
    error: !!errors.length
  });

  return (
    <div className={className}>
      <SelectSimple
        {...restProps}
        id={id}
        value={value}
        onChange={onChangeWrapper}
        defaultValue={props.defaultValue}
      />
      {!!errors.length && (
        <div className="errors-container">
          {errors.map((e, i) => (
            <div key={i} className="error-item">
              {e.fieldError}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Select;
