import React, { useContext } from "react";
// import Tag from "../tag";
import parse from 'html-react-parser';
import "./index.scss";
// import Calender from "../../assets/icons/calender";
import { formatDate } from "../../utils/commonMethods";
import { 
  Link, 
  Paragraph, 
  Title, 
  CompanyIcon,
  TagIcon,
  ProductsIcon,
  LightningIcon,
  ThemeContext, 
  Tag,
  Checkbox
} from '@opsdti-global-component-library/amgen-design-system'
import { Tag as ExtTag } from '../../pages/news-types'
import Trash from "../../assets/icons/trash-can";
import { BASE_ROUTE } from "../../utils/routing";

type TagType = 'BASIC' | 'CHECKABLE'
type ArticleProps = {
  isEven?: boolean;
  redirectUrl?: string;
  title?: string;
  articleId?: string;
  publishDate?: string;
  publishSource?: string;
  tagList?: ExtTag[];
  summary?: string;
  ellipsisRequired?: boolean;
  checkboxFlag: boolean;
  isChecked?: boolean;
  trashFlag: boolean;
  tagType?: TagType;
  hideArticleInfo?: boolean;
  onCheckboxClicked?: (checkedArticle: {
    isChecked: boolean;
    articleId: string | undefined; // We should fix type in main file so we do not need to add undefined here
    articleName: string | undefined;
  }) => void;
  handleDelete?: (selectedData: any, title?: string) => void;
};

const ArticleCard: React.FC<ArticleProps> = (props: ArticleProps) => {
  const {
    isEven = true,
    redirectUrl,
    title,
    publishDate,
    publishSource,
    checkboxFlag,
    isChecked,
    trashFlag,
    articleId,
    tagList,
    summary,
    ellipsisRequired,
    onCheckboxClicked,
    handleDelete,
    tagType = 'CHECKABLE',
    hideArticleInfo = false
  } = props;
  const { theme } = useContext(ThemeContext)
  const { colorBgLayout, colorBgContainer } = theme?.token as any

  const handleTopicClick = (articleId: any) => {
    if (props.redirectUrl === "" || props.redirectUrl === null) {
      const finalUrl = `${BASE_ROUTE}/news/${articleId}.html`;
      window.open(finalUrl);
      return;
    }
    let finalUrlLink=redirectUrl?.replace(/^http:\/\//i, "https://");
    window.open(finalUrlLink);
  };

  const getCapitalizeData = (str: any) => {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getmodifiedContent = (data: string) => {

    const regex = /(- |\n- )/g
    const finalData = data && data.replace(regex, ' ');
    return <Paragraph className={ellipsisRequired ? "ext-article-card-summary" : ""}>{parse(finalData)}</Paragraph>
  }

  const getTagIcon = (type: string) => {
    if(type === "apoc_company" || type === "company" || type === "institution" || type === "company_names"){
      return <CompanyIcon height={16} width={16} />
    } else if(type === "topic" || type === "modality" || type === "disease_area" || type === "target" || 
              type === "topic_names" || type === "corex_topic_names" || type === "corexTopic" || type === "corex_topic"  || type === "phase"){
      return <TagIcon height={16} width={16} />
    } else if(type === "product" || type === "apoc_product" || type === 'product_names'){
      return <ProductsIcon height={16} width={16} />
    } else if (type === "av_company" || type === "av_company_names" || type === "ventureCompany" || type === "kiq_names" || type==="kiq"){
      return <LightningIcon height={16} width={16} />
    } else {
      console.log('uncaught type: ', type)
    }
  }

  const chooseTag = (tag: ExtTag, index: number) => {
    if(tagType === 'BASIC') {
      return <Tag 
          icon={getTagIcon(tag.type)}
          text={tag.name} 
          key={index} 
          onClick={tag.onClick || (() => {})}
          style={{backgroundColor: '#fff'}} // Design system issue
        />
    } else if (tagType === 'CHECKABLE') {
      return (
        <Tag.Checkable
          icon={getTagIcon(tag.type)}
          onClick={tag.onFilter}
          checked={tag.isFilteredOnTag}
          text={tag.name}
          key={index}
        />
      )
    }
  }
  return (
    <div className={`ext-article-card-dscu`} 
         style={isEven 
          ? {backgroundColor: colorBgLayout} 
          : {backgroundColor: colorBgContainer}}>
      <div className="article-details ext-d-flex ext-border-top-0">
        {checkboxFlag ? (
          <div className="ext-article-checkbox">
            <Checkbox
              className="form-check-input ext-article-card-checkbox ops-mt-2"
              value=""
              checked={isChecked}
              onChange={(event) =>
                onCheckboxClicked?.({
                  isChecked: event.target.checked,
                  articleId: articleId,
                  articleName: title,
                })
              }
              id="flexCheckDefault"
            ></Checkbox>
          </div>
        ) : null}
        <div className="ext-flex-grow-1">
          <div onClick={() => handleTopicClick(articleId)}>
            <Title level={3}>
              <Link className="ext-article-title" type="secondary">
                  {title}
              </Link>
            </Title>
          </div>
          {!hideArticleInfo && (
            <div className="ext-article-info">
              <Paragraph>
                {formatDate(publishDate)} | {getCapitalizeData(publishSource)}
              </Paragraph>
            </div>
          )}
          <Paragraph className="ext-article-summary">
            {summary && getmodifiedContent(summary)}
          </Paragraph>
          <div className="ext-article-tags">
            {tagList != undefined && tagList.map((item: ExtTag, index: number) => chooseTag(item, index))}
          </div>
        </div>
        {trashFlag ? (
          <div
            className="ext-trash-bin"
            onClick={() => handleDelete?.(articleId, title)}
          >
            <Trash fill="#797979" classname="ext-tag-icon" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ArticleCard;
