import axios from "axios";
import { Array, Number, Record, Static, String } from "runtypes";
import {
  ChangeTypeValidator,
  PageInfoValidator,
  SortInfoValidator} from "./common";
import { getAgendaId } from "utils/multitenancy";

const ChangeLogItemValidator = Record({
  id: Number,
  agendaItemId: Number,
  agendaItemName: String,
  changeBy: String,
  createdDate: String,
  changeType: ChangeTypeValidator,
  description: String
});

export type ChangeLogItem = Static<typeof ChangeLogItemValidator>;

const ChangeLogFiltersValidator = Record({
  dateRange: Array(String),
  agendaItemIds: Array(Number),
  changeTypes: Array(String)
});

export type ChangeLogFilters = Static<typeof ChangeLogFiltersValidator>;

const FilterItemsValidator = Record({
  label: String,
  value: Number
});

export type FilterItems = Static<typeof FilterItemsValidator>;

const GetChangeLogItemNamesResponseValidator = Record({
  data: Array(FilterItemsValidator)
})

export type GetChangeLogItemNamesResponse = Static<typeof GetChangeLogItemNamesResponseValidator>;

const ChangeLogInfoValidator = Record({
  filters: ChangeLogFiltersValidator,
  sortInfo: SortInfoValidator,
  pageInfo: PageInfoValidator,
})

export type ChangeLogInfo = Static<typeof ChangeLogInfoValidator>;

const GetChangeLogResponseValidator = Record({
  info: ChangeLogInfoValidator,
  data: Array(ChangeLogItemValidator)
});

export type GetChangeLogResponse = Static<typeof GetChangeLogResponseValidator>;

export const getChangeLog = async (info: ChangeLogInfo):
Promise<GetChangeLogResponse> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.post<GetChangeLogResponse>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/change_log`,
    info
  );

  GetChangeLogResponseValidator.check(result.data);

  return result.data;
};

export const getChangeLogItemNames = async ():
Promise<GetChangeLogItemNamesResponse> => {
  const { REACT_APP_DOMAIN } = window.__RUNTIME_CONFIG__;

  const result = await axios.get<GetChangeLogItemNamesResponse>(
    `${REACT_APP_DOMAIN}/api/v1/${getAgendaId()}/change_log/agenda_item_names`
  );

  GetChangeLogItemNamesResponseValidator.check(result.data);

  return result.data;
};
