import React from "react";
import { AutoUpdater, AutoUpdaterType } from "global/use-auto-updater";
import Accordion from "common-components/accordion/Accordion";
import { Checkbox } from "common-components";
import { QueryStatus } from "@tanstack/react-query";
import { Loader } from "@gitlab-rtsensing/component-library";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import './FilterAccordion.scss';

const namespace = "rts-pa-filter-accordion";

export type InputWithId = { id: string; value: string; order: number };

export type FilterAccordionCheckboxType = {
  id: string;
  label: string;
  isChecked: boolean;
};

type Props<T extends AutoUpdaterType> = {
  status: QueryStatus;
  title: string;
  placeholder?: string;
  options: FilterAccordionCheckboxType[];
  autoUpdater: AutoUpdater<T>;
  filterType: "key" | "row";
};

export const FilterAccordion = <T extends AutoUpdaterType>(
  props: Props<T>
): JSX.Element => {
  return (
    <div className={namespace}>
      <Accordion
        title={props.title}
        startOpen={true}
        accordionSyncId={`${namespace}-drawer-keys`}
        postContent={
          <AdvancedTooltip
            tooltip={{
              sections: [
                {
                  title:
                    props.filterType === "row"
                      ? "Row Name Filter"
                      : "Calendar Key Filter",
                  rows: [
                    {
                      label: null,
                      content: [
                        props.filterType === "row"
                          ? "Only the selected rows will be shown, hiding any unselected rows for the calendar view. Select Reset Filters below to deselect all filters."
                          : "When you select a filter or filters, all other milestones on the calendar will be dimmed, making the selected filter options stand out more clearly."
                      ]
                    }
                  ]
                }
              ]
            }}
          />
        }
      >
        {props.status !== "success" ? (
          <Loader status={props.status} />
        ) : (
          <>
            {props.options.map(
              (option: FilterAccordionCheckboxType, i: number) => {
                return (
                  <Checkbox
                    className={`${namespace}-checkbox`}
                    key={option.id}
                    id={option.id}
                    label={option.label}
                    autoUpdater={{
                      ...props.autoUpdater,
                      propExpression: x =>
                        (
                          props.autoUpdater.propExpression(
                            x
                          ) as FilterAccordionCheckboxType[]
                        )[i].isChecked
                    }}
                  />
                );
              }
            )}
          </>
        )}
      </Accordion>
    </div>
  );
};

export default FilterAccordion;
