import { Label, MilestoneTypeIcon, Text } from "common-components";
import React from "react";
import "./CalendarKeys.scss";
import { useQuery } from "@tanstack/react-query";
import { getMilestoneCalendarKeys } from "api";
import Tag from "../../common-components/tag/Tag";
import { useCalendarContext } from "pages/key-events/CalendarContext";
import produce from "immer";

const namespace = "rts-pa-key-events-page-calendar-keys";

export const CalendarKeys = () => {
  const { data } = useQuery(["calendar-keys"], getMilestoneCalendarKeys);
  const { calendarFilters, setCalendarFilters } = useCalendarContext();

  const keyOnClick = (id: number) => {
    const newCalendarFilters = produce(calendarFilters, draftState => {
      const foundKey = draftState.keys.find(key => key.id === id.toString());
      if (foundKey) {
        foundKey.isChecked = !foundKey.isChecked;
      }
    });

    setCalendarFilters(newCalendarFilters);
  };

  const getIsKeyChecked = (id: number) => {
    const item = calendarFilters.keys.find(key => key.id === id.toString());
    return item ? item.isChecked : false;
  };

  return (
    <div className={namespace}>
      <Label className={`${namespace}-legend-label`}>Calendar Keys</Label>
      {data ? (
        data.keys.map(
          mck =>
            !mck.isHiddenOnLegend && (
              <Tag
                isToggled={getIsKeyChecked(mck.id)}
                key={mck.id}
                icon={
                  <MilestoneTypeIcon
                    key={mck.id}
                    id={mck.id}
                    showLabel
                    size="small"
                  />
                }
                onClick={() => keyOnClick(mck.id)}
              />
            )
        )
      ) : (
        <Text>Loading...</Text>
      )}
    </div>
  );
};

export default CalendarKeys;
