import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "common-components";
import { getOktaAuthConfig } from "./global/okta-config";
import AxiosProvider from "api/axios-context";
import AuthenticationWrapper from "AuthenticationWrapper";
import AppRoutes from "pages/routes";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { enableMapSet } from "immer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ThemeProvider } from "@opsdti-global-component-library/amgen-design-system";

type AppProps = {
  themeService: unknown | undefined;
};

function App(props: AppProps) {
  const navigate = useNavigate();

  //initialize app-wide settings on load
  useEffect(() => {
    enableMapSet();
    dayjs.extend(utc); // eslint-disable-line import/no-named-as-default-member
    dayjs.extend(timezone); // eslint-disable-line import/no-named-as-default-member
    dayjs.tz.setDefault("America/Los_Angeles");
  }, []);

  const oktaAuth = useMemo(() => {
    const auth = new OktaAuth(getOktaAuthConfig());

    //used to fix incorrect console warning: https://github.com/okta/okta-react/issues/227
    auth.options.restoreOriginalUri = undefined;

    return auth;
  }, []);

  const restoreOriginalUri = useCallback(
    (_oktaAuth: OktaAuth, originalUri: string) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { refetchOnMount: "always", staleTime: Infinity }
    },
    queryCache: new QueryCache({
      onError(error, query) {
        console.error(error); // eslint-disable-line no-console
      }
    })
  });

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <ThemeProvider themeService={props.themeService}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <AxiosProvider>
            <QueryClientProvider client={queryClient}>
              <AuthenticationWrapper>
                <AppRoutes />
                <ReactQueryDevtools />
              </AuthenticationWrapper>
            </QueryClientProvider>
          </AxiosProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </Security>
  );
}

export default App;
