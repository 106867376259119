/* eslint-disable */
import { NavLink, NavLinkSections, NavManager } from '@amgen/rtsensing-nav'
import { useEffect } from 'react'
import { BASE_ROUTE } from '../components/../utils/routing'
/* one navManager instance per site */
let navManager: NavManager | undefined
if (NavManager) {
  navManager = new NavManager('Intelligence')
}

interface ApplicationNavLinks {
  peerBigSheet: NavLink
  analystReport: NavLink
  transcripts: NavLink
  news: NavLink
  companies: NavLink
  newsCurator: NavLink
  topics: NavLink
  oncology: NavLink
  products: NavLink
  trending: NavLink
  kiq: NavLink
  experimentalPage: NavLink
  avCompany: NavLink
  kiqBuilder: NavLink
  earnings: NavLink
  flashLink: NavLink
  businessDevelopment: NavLink
}

const applicationNavLinks: ApplicationNavLinks = {
  trending: {
    label: 'Intelligence Home',
    // label: 'Trending News',
    url: `${BASE_ROUTE}`,
    disabled: false,
  },
  news: {
    label: 'News Article Search',
    url: `${BASE_ROUTE}/news-search`,
    disabled: false,
  },
  products: {
    label: 'Products',
    url: `${BASE_ROUTE}/products`,
    disabled: false,
  },
  topics: {
    label: 'Topics',
    url: `${BASE_ROUTE}/topics`,
    disabled: false,
  },
  newsCurator: {
    label: 'News Curator',
    url: `${BASE_ROUTE}/news-curator`,
    disabled: false,
  },
  kiqBuilder: {
    label: 'Key Intelligence Question Builder',
    url: `${BASE_ROUTE}/builder`,
    disabled: false,
  },
  avCompany: {
    label: 'Amgen Ventures',
    url: `${BASE_ROUTE}/amgen-ventures`,
    disabled: false,
  },
  oncology: {
    label: 'Oncology',
    url: `${BASE_ROUTE}/ta/oncology`,
    disabled: false,
  },
  kiq: {
    label: 'Corporate Strategy',
    url: `${BASE_ROUTE}/corporate-strategy`,
    disabled: false,
  },
  earnings: {
    label: 'Earnings',
    url: `${BASE_ROUTE}/earnings`,
    disabled: false,
    subMenu: [
      { label: 'Earnings Sentiment', url: '#earningsSentiment', link: `${BASE_ROUTE}/earnings` },
      { label: 'Investor Relations Insights', url: '#investorRelationsInsights', link: `${BASE_ROUTE}/earnings` },
      { label: 'Earnings Financial Summary', url: '#financialSummary', link: `${BASE_ROUTE}/earnings` },
      { label: 'Earnings Comparative Analysis', url: '#comparativeAnalysis', link: `${BASE_ROUTE}/earnings` },
    ]
  },
  companies: {
    label: 'Company Financials',
    url: `${BASE_ROUTE}/companies`,
    disabled: false,
  },
  peerBigSheet: {
    label: 'Peer Big Sheet',
    url: `${BASE_ROUTE}/peer-big-sheet`,
    disabled: false,
  },
  experimentalPage: {
    label: 'Experimental Features',
    url: `${BASE_ROUTE}/experimental`,
    disabled: false,
  },
  analystReport: {
    label: 'Analyst Reports',
    url: 'https://amgen.sharepoint.com/sites/AmgenInvestorRelations/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=1jJaVN&CID=86677aa1%2D604c%2D7000%2Dcd7c%2D2e7487194673&cidOR=SPO&FolderCTID=0x01200050C312E3F67BEB4AA5D0188F1F55155C&OR=Teams%2DHL&CT=1737656705350&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDEyMDEwMDIyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fsites%2FAmgenInvestorRelations%2FShared%20Documents%2FAnalyst%20Research%20Reports&viewid=d76dfb39%2D946e%2D4a3c%2D9364%2Ddfee4ac2e504',
    disabled: false,
  },
  transcripts: {
    label: 'Transcripts',
    url: 'https://amgen.sharepoint.com/sites/AmgenInvestorRelations/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=1jJaVN&CID=86677aa1%2D604c%2D7000%2Dcd7c%2D2e7487194673&cidOR=SPO&FolderCTID=0x01200050C312E3F67BEB4AA5D0188F1F55155C&OR=Teams%2DHL&CT=1737656705350&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDEyMDEwMDIyMSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D&id=%2Fsites%2FAmgenInvestorRelations%2FShared%20Documents%2FAnalyst%20Research%20Reports%2F2025%20Transcripts%20and%20Summaries&viewid=d76dfb39%2D946e%2D4a3c%2D9364%2Ddfee4ac2e504',
    disabled: false,
  },
  flashLink: {
    label: 'FLASH (Fast Literature Analysis & Summary Helper)',
    url: 'https://flashapp.amgen.com/',
    disabled: false,
  },
  businessDevelopment: {
    label: 'BD',
    url: `${BASE_ROUTE}/business-development`,
    disabled: false,
  },
}

const initialSections: NavLinkSections = {
  internalSections: [
    { sectionLabel: '', links: [applicationNavLinks.trending] },
    { sectionLabel: 'IN THE NEWS', links: [applicationNavLinks.news, applicationNavLinks.products, applicationNavLinks.topics] },
    { sectionLabel: 'CUSTOMIZED NEWS', links: [applicationNavLinks.newsCurator, applicationNavLinks.kiqBuilder] },
    { sectionLabel: 'INSIGHTS', links: [applicationNavLinks.avCompany, applicationNavLinks.oncology, applicationNavLinks.kiq] },
    { sectionLabel: 'FINANCIALS', links: [applicationNavLinks.earnings, applicationNavLinks.companies, applicationNavLinks.peerBigSheet] },
    { sectionLabel: 'EXPERIMENTAL', links: [applicationNavLinks.experimentalPage] },
    { sectionLabel: 'BUSINESS DEVELOPMENT', links: [applicationNavLinks.businessDevelopment] }
  ],
  externalSections: [
    { sectionLabel: '', links: [applicationNavLinks.analystReport, applicationNavLinks.flashLink, applicationNavLinks.transcripts] }
  ],
  metaData: { hideMainNavBar: false, hideSideNavBar: false }
}

function removeInternalSection(
  sections: NavLinkSections,
  subSection: NavLink
): NavLinkSections {
  if (!sections.internalSections) {
    return sections
  }

  let intSectionIndex = -1

  for (let i = 0; i < sections.internalSections.length; i++) {
    const intSection = sections.internalSections[i]
    const index = intSection.links.findIndex(s => s.label === subSection.label)

    if (index >= 0) {
      intSection.links.splice(index, 1)
      intSectionIndex = i
      break
    }
  }

  /* if no more sub-sections, remove overall section */
  if (
    intSectionIndex >= 0 &&
    sections.internalSections[intSectionIndex]?.links?.length === 0
  ) {
    sections.internalSections.splice(intSectionIndex, 1)
  }

  return sections
}

function removeSubmenu(
  sections: NavLinkSections,
  subSection: NavLink,
  removeSubMenuLabel: string
): NavLinkSections {
  if (!sections.internalSections) {
    return sections
  }

  for (let i = 0; i < sections.internalSections.length; i++) {
    const index = sections.internalSections[i].links.findIndex(s => s.label === subSection.label)

    if (index >= 0) {
      const subMenu = sections.internalSections[i].links[index]?.subMenu;
      if (subMenu && subMenu.length > 0) {
        for (let j = 0; j < subMenu?.length; j++) {
          if (subMenu[j].label === removeSubMenuLabel) {
            subMenu.splice(j, 1)
            break
          }
        }
      }
    }
  }

  return sections
}

export function useNavManager(permissions: any, hideMainNavBar?: boolean, hideSideNavBar?: boolean) {
  useEffect(() => {
    if (!navManager || !permissions) {
      return
    }

    
    const newSections = structuredClone(initialSections);

    // Define a mapping between permissions and navigation links
    const permissionLinkMapping = {
        trending: applicationNavLinks.trending,
        news: applicationNavLinks.news,
        products: applicationNavLinks.products,
        topics: applicationNavLinks.topics,
        newsCurator: applicationNavLinks.newsCurator,
        kiqBuilder: applicationNavLinks.kiqBuilder,
        avCompany: applicationNavLinks.avCompany,
        oncology: applicationNavLinks.oncology,
        kiq: applicationNavLinks.kiq,
        earnings: applicationNavLinks.earnings,
        companies: applicationNavLinks.companies,
        peerBigSheet: applicationNavLinks.peerBigSheet,
        experimentalPage: applicationNavLinks.experimentalPage,
        businessDevelopment: applicationNavLinks.businessDevelopment
    };

    // Iterate over the mapping and update sections based on permissions
    Object.entries(permissionLinkMapping).forEach(([permissionKey, navLink]) => {
      if (permissions && !permissions.externalAuthorization[permissionKey]) {
        removeInternalSection(newSections, navLink);
      }
    });

    if (permissions && !permissions.externalAuthorization.wordCloud) {
      removeSubmenu(newSections, applicationNavLinks.earnings, 'Earnings Sentiment')
    }


    newSections.metaData = {
      hideMainNavBar: !!hideMainNavBar,  //return false if undefined
      hideSideNavBar: !!hideSideNavBar
    }
    navManager.setNavLinkSections(newSections).catch((e) => {console.log(e)}) //Sub-Links will not apprear if remove this catch block
  }, [permissions]) 
} 
