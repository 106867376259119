import React, { useCallback, useMemo } from "react";
import "./index.scss";

// Components
import { Loader } from "@gitlab-rtsensing/component-library"; 
import { Popover } from '@opsdti-global-component-library/amgen-design-system';
import InfoIcon from "../../../assets/icons/info-icon";

type EarningsBarGraphProps = {
  noLabel?: boolean;
  earningsGraphData?: any;
  isLoading?: boolean;
  isError: string;
  amgenRankingValue?: string;
  selectedQuarter?: any;
  selectedYear?: any;
  isToolTip?: boolean;
};

const EarningsBarGraph: React.FC<EarningsBarGraphProps> = (props: EarningsBarGraphProps) => {
  const { noLabel, earningsGraphData, isLoading, isError, amgenRankingValue, selectedQuarter, selectedYear, isToolTip } = props;
  const sortedData = useMemo(() => {
    return earningsGraphData.sort((a:any, b:any) => {
      // To handle case where % value is null 
      if(a.value === null) return 1;
      if(b.value === null) return -1;
  
      const percentA = parseFloat(a.value) / 100;
      const percentB = parseFloat(b.value) / 100;
      return percentB - percentA;
    });
  }, [earningsGraphData]);

  const chartHasNegativeValue:boolean = useMemo(() => {
    return sortedData.some((item:any) => parseFloat(item.value?? '0') < 0);
  }, [sortedData]);
  
  const convertValue = useCallback((value:any) => { 
    let newValue = 0; 
    if(amgenRankingValue !== "Revenue"){
      newValue = value && parseFloat(String(value)?.replace('%', ''));
    }else{
      let valueInBillions = value / 1000;
      newValue = value && parseFloat(valueInBillions.toFixed(1)); 
    } 
  
    if(newValue < 0) newValue = newValue * -1;
    return newValue;
  }, [amgenRankingValue]) 

  const getLastNonNullValue:number = useMemo(() => {
    let index = sortedData.length-1;
    while (index>1 && !sortedData[index]?.value && index--);
    return index;
  }, [sortedData]);

  const widthMultiplier:number  = useMemo(() => {
    if(sortedData.length && sortedData[0]?.value){
      let firstValue = convertValue(sortedData[0]?.value?? '0'); 
      let lastNonNullValue = convertValue(sortedData[getLastNonNullValue]?.value?? '0');
      let greatestValue=firstValue>lastNonNullValue?firstValue:lastNonNullValue;
      const multiplier =  (100 / greatestValue);
      return !multiplier? 1 : parseFloat(String(multiplier));
    }
    return 1;
  }, [getLastNonNullValue, convertValue, sortedData]) 

  const checkNegativeValue = (value:string) => {
    return parseFloat(value?? '0') < 0;
    } 

  const calculateBarWidth = useCallback((value:any) => { 
    let newValue = convertValue(value); 
    const isNegativeValue = checkNegativeValue(value)
    if(!isNegativeValue && chartHasNegativeValue)
      return `${(newValue * widthMultiplier*.6666)}%`
    if(!chartHasNegativeValue)
      return `${(newValue * widthMultiplier*.8)}%`
    return widthMultiplier? `${(newValue * widthMultiplier)}%` : `${(newValue)}%`;
  }, [convertValue, widthMultiplier]) 

  const revenueValueInDollars = useCallback((value:number) => {
    let valueInBillions = (value / 1000).toFixed(1)
    return `$${valueInBillions}B`;
  }, []) 

  const getValuesForGraph = (value:string) => {
    if(value === null){
     return "No data available";
    }else {
      return value;
    } 
  }

  return (
    <div className="ext-earnings-bar-graph-wrapper ext-col-12 ext-col-md-6">
      <div className="ext-earnings-headings-text ops-mb-2">
        {!noLabel && <p className="ops-fs-12 ext-fw-bold ops-my-5">
          <span>
            {selectedQuarter === "Full Year" ? "FY" : selectedQuarter.includes("Q") ? selectedQuarter : `Q${selectedQuarter}`} {selectedYear}
          </span>
          {" "}
          {isToolTip?    
            <Popover
              id="tooltip"
              data-html={true}
              content={"Please note: Historical data displayed may not fully account for financial restatements or adjustments."}
            >
            <a data-tooltip-id="tooltip">
              <InfoIcon fill="#9C9C9C" />
            </a>
            </Popover>:null
          }
        </p>}
      </div>
      {!isError
      ?
      <>
          {isLoading 
        ?
        <div className="ext-earning-table-loader"><Loader/></div>
        :
        <>{earningsGraphData.length > 0
        ? 
        <>{sortedData.map((item:any, index:number) => {
          const isNegativeValue = checkNegativeValue(item.value)
          const barStyle = {
            width: calculateBarWidth(item.value),
            background: item.company_ticker === "AMGN" ? "#0063C3" : "#E3E3E3",
          };
          const wrapperStyle = {
            justifyContent:isNegativeValue? 'flex-end' : 'flex-start',
            width: chartHasNegativeValue? isNegativeValue?'40%':'60%' : '100%'
          }
          return (
            <div className="ext-earnings-content-item" 
              key={index}
              style={{backgroundColor: item.company_ticker === 'AMGN' ? "rgba(117, 168, 221, 0.20)" : "white"}}>
              <img className="ext-earnings-company-img" src={item.company_image} alt={item.company_ticker}></img>
              <p className="ext-earnings-graph-company-name">{item.company_name}</p>
              <div className="ext-earnings-graph-container d-flex">
                {chartHasNegativeValue && !isNegativeValue ? <div className='ext-earnings-content-wrapper' style={{width: '40%'}}/> : ''}
                {
                  <div className="ext-earnings-content-wrapper" style={wrapperStyle}>
                    <div className="ext-earnings-graph-data" style={barStyle} />
                    {!isNegativeValue && <p className="ext-earnings-graph-percentage">
                      {amgenRankingValue === 'Revenue' ? 
                      (item.value ? revenueValueInDollars(item.value) : "No data available") 
                      : getValuesForGraph(item?.value)} </p>}
                  </div>
                }
                {chartHasNegativeValue && isNegativeValue ? 
                  <div className='ext-earnings-content-wrapper' style={{width: '60%', justifyContent:'flex-start'}} > 
                    <p className="ext-earnings-graph-percentage">{getValuesForGraph(item?.value)}</p>
                  </div>
                  : ''}
                </div>
            </div>) 
        })
        }</>
        :
        <div className="ext-earnings-table-error">No data available</div>}
        </>
        }
      </>
      :
      <div className="ext-earnings-table-error">{isError}</div>
      }
    </div>
  );
};

export default EarningsBarGraph;
