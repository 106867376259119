import React from "react";
import "./UpsertCalendarModalCalendarKey.scss";
import {
  Label,
  Select,
  Input,
  SelectSimple,
  MilestoneTypeIcon,
  Text,
  Checkbox
} from "common-components";
import {
  AutoUpdaterPropExpression,
  AutoUpdaterSetItem,
  AutoUpdaterType
} from "global/use-auto-updater";
import { FieldValUtils } from "global/use-validator";
import TrashIcon from "icons/trash-icon";
import DragIcon from "icons/drag-icon";
import { Draggable } from "react-beautiful-dnd";
import classnames from "classnames";
import { AdvancedTooltip } from "common-components/advanced-tooltip/AdvancedTooltip";
import { CircleIcon } from "icons/key-events-icons";
import { MilestoneCalendarKeyIcon } from "api";
import { colors } from "utils";

const namespace = "rts-pa-upsert-calendar-modal-calendar-key";

export type Props<T extends AutoUpdaterType> = {
  title: string;
  id: number;
  className?: string;
  autoUpdater: {
    item: T;
    propExpressionLabel: AutoUpdaterPropExpression<T>;
    propExpressionColor: AutoUpdaterPropExpression<T>;
    propExpressionIcon: AutoUpdaterPropExpression<T>;
    propExpressionIsHiddenOnLegend: AutoUpdaterPropExpression<T>;
    setItem: AutoUpdaterSetItem<T>;
  };
  autoUpdaterIndex: number;
  validator: FieldValUtils;
  onDeleteClick: (i: number) => void;
  order: number;
  type: string;
  isDeletable: boolean;
};

export const UpsertCalendarModalCalendarKey = <T extends AutoUpdaterType>(
  props: Props<T>
): JSX.Element => {
  const title = props.title;

  const trashIcon = (
    <TrashIcon
      className={`${
        props.type !== "custom" ? "trash-icon-disabled" : "trash-icon"
      }`}
      height={15}
      width={16}
      onClick={() =>
        props.isDeletable && props.onDeleteClick(props.autoUpdaterIndex)
      }
    />
  );

  const tooltipContent = {
    sections: [
      {
        rows: [
          {
            label: "Deleting this key is not possible",
            content: [
              `${
                props.type !== "custom"
                  ? "The Launch, Product Milestone, and End-to-End Milestone keys exist on all calendars and can be hidden, but not deleted."
                  : "Milestones assigned to this key must be reassigned to a different key before this key can be deleted."
              }`
            ]
          }
        ]
      }
    ]
  };

  const iconColors = [
    { colorLabel: "Pink", color: colors.pinkAccent },
    { colorLabel: "Orange", color: colors.orange },
    { colorLabel: "Yellow", color: colors.yellowAccent },
    { colorLabel: "Green", color: colors.greenAccent },
    { colorLabel: "Pale Blue", color: colors.paleBlue },
    { colorLabel: "Medium Blue", color: colors.mediumBlue },
    { colorLabel: "Dark Blue", color: colors.primaryBlue },
    { colorLabel: "Lavender", color: colors.lavender },
    { colorLabel: "Purple", color: colors.purpleAccent },
    { colorLabel: "Black", color: colors.blackAccent },
    { colorLabel: "Gray", color: colors.grayAccent }
  ];

  const currentColor = props.autoUpdater.propExpressionColor(
    props.autoUpdater.item
  );
  const filteredIconColors = iconColors.filter(
    color => color.colorLabel !== "Gray" || color.color === currentColor
  );

  const colorOptions = filteredIconColors.map((color, i) => (
    <SelectSimple key={i} value={color.color}>
      <div className={`${namespace}-select-option`}>
        <CircleIcon
          fill={`${props.type !== "custom" ? colors.grayAccent : color.color}`}
        />
        <>{color.colorLabel}</>
      </div>
    </SelectSimple>
  ));

  const icons = [
    { label: "Circle", shape: "circle" },
    { label: "Triangle", shape: "triangle" },
    { label: "Square", shape: "square" },
    { label: "Eclipse", shape: "eclipse" },
    { label: "Pentagon", shape: "pentagon" },
    { label: "Diamond", shape: "diamond" },
    { label: "Heptagram", shape: "heptagram" },
    { label: "Dash", shape: "dash" },
    { label: "Bar", shape: "bar" }
  ];

  const currentIcon = props.autoUpdater.propExpressionIcon(
    props.autoUpdater.item
  );
  const filteredIcons = icons.filter(
    icon => icon.shape !== "bar" || icon.shape === currentIcon
  );

  const iconOptions = filteredIcons.map((icon, i) => (
    <SelectSimple key={i} value={icon.shape}>
      <div className={`${namespace}-select-option`}>
        <MilestoneTypeIcon
          icon={icon.shape as MilestoneCalendarKeyIcon}
          size="large"
          color={`${
            props.type !== "custom" ? colors.grayAccent : colors.gray550
          }`}
        />
        <Text>{icon.label}</Text>
      </div>
    </SelectSimple>
  ));

  return (
    <Draggable draggableId={props.id.toString()} index={props.autoUpdaterIndex}>
      {(provided, snapshot) => {
        const rootClassName = classnames(namespace, {
          isDragging: snapshot.isDragging
        });

        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={provided.draggableProps.style}
            className={rootClassName}
          >
            <Label>{title}</Label>
            <div className={`${namespace}-container`}>
              <div className={`${namespace}-container-inputs`}>
                <Input
                  id={`${props.id}`}
                  placeholder="Key Label"
                  autoUpdater={{
                    ...props.autoUpdater,
                    propExpression: props.autoUpdater.propExpressionLabel
                  }}
                  validator={props.validator}
                  required={true}
                  disabled={props.type !== "custom"}
                />
                <div className={`${namespace}-container-two-column`}>
                  <Select
                    // optionLabelProp="value"
                    autoUpdater={{
                      ...props.autoUpdater,
                      propExpression: props.autoUpdater.propExpressionColor
                    }}
                    placeholder="Select Color"
                    required={true}
                    disabled={props.type !== "custom"}
                  >
                    {colorOptions}
                  </Select>
                  <Select
                    autoUpdater={{
                      ...props.autoUpdater,
                      propExpression: props.autoUpdater.propExpressionIcon
                    }}
                    placeholder="Select Shape"
                    required={true}
                    disabled={props.type !== "custom"}
                  >
                    {iconOptions}
                  </Select>
                </div>
                <Checkbox
                  label="Hide within calendar legend"
                  autoUpdater={{
                    ...props.autoUpdater,
                    propExpression:
                      props.autoUpdater.propExpressionIsHiddenOnLegend
                  }}
                />
              </div>
              <div className={`${namespace}-container-controls`}>
                {props.isDeletable ? (
                  trashIcon
                ) : (
                  <AdvancedTooltip
                    placement="left"
                    tooltip={tooltipContent}
                    tooltipIcon={trashIcon}
                    trigger="click"
                  />
                )}

                <div
                  className={`${namespace}-drag`}
                  {...provided.dragHandleProps}
                >
                  <DragIcon className="drag-icon" height={12} width={16} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Draggable>
  );
};

export default UpsertCalendarModalCalendarKey;
