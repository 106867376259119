export const colors = {
  //grayscale
  white: "#FFF",
  gray100: "#FFF",
  gray200: "#F9F9F9",
  gray300: "#E3E3E3",
  gray400: "#C4C4C4",
  gray500: "#9C9C9C",
  gray550: "#8C8C8C",
  gray600: "#585858",
  gray700: "#2F2F2F",
  black: "#000",

  //semantic colors
  green: "#008533",
  red: "#DC3545",
  yellow: "#D07804",

  //accent colors
  teal: "#15A2AC",
  purple: "#5610EB",
  lightBlue: "#7FB0E0",
  pinkAccent: "#E377C2",
  yellowAccent: "#FDB81E",
  orange: "#FF8C53",
  greenAccent: "#8EB817",
  paleBlue: "#88E7F7",
  mediumBlue: "#00BCE4",
  lavender: "#C1B2FF",
  purpleAccent: "#7E6FFF",
  blackAccent: "#1F1F1F",
  grayAccent: "#BFBFBF",

  //base colors
  primary: "#3773D2",
  primaryBlue: "#0063C3",
  secondary: "#585858",
  tertiaryBlue: "#004D99",
  success: "#008533",
  info: "#15A2AC",
  warning: "#D07804",
  danger: "#DC3545",
  light: "#FFF",
  dark: "#2F2F2F"
};

export default colors;
